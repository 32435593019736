import { useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { TwoColumnDetails, Section } from 'common/Details'
import { Tabs, Spinner, ErrorComponent, Table, Badge } from 'common'
import {
	JobsServices,
	StaffServices,
	TimesheetServices,
	VisitServices,
	VisitTimesheetsServices,
} from 'services'
import { AppRoutes } from 'config'
import { Notes } from 'components/Notes'
import { time } from 'console'
import moment from 'moment'
import { CheckIcon, PencilIcon } from '@heroicons/react/24/solid'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Column } from 'primereact/column'
import { ApproveTimesheet } from 'components/Timesheets'

export const TimesheetDetails = () => {
	const [tabIndex, setTabIndex] = useState(0)

	const { id } = useParams()
	const location = useLocation()
	const {
		data: timesheetData,
		isLoading: timesheetLoading,
		error: timesheetError,
	} = TimesheetServices.useTimesheetById(id)
	const { updateVisitTimesheet } =
		VisitTimesheetsServices.useUpdateTimesheetById()
		const { data: visitsData, isLoading: visitsLoading } = VisitServices.useVisits()

	// Visit Timesheets for this date and this staffID

	// const { data: staffData, isLoading: staffLoading, error: staffError } = StaffServices.useStaffById(timesheetData?.staff_id)
	const {
		data: visitTimesheets,
		isLoading: visitTimesheetsLoading,
		error: visitTimesheetsError,
	} = VisitTimesheetsServices.useVisitTimesheets()

	if (timesheetLoading || visitTimesheetsLoading || visitsLoading) {
		return (
			<div className="w-full h-48 flex justify-center items-center">
				<Spinner />
			</div>
		)
	}

	if (timesheetError) {
		return <ErrorComponent />
	}

	const filteredVisitTimesheets = visitTimesheets?.filter(
		(visitTimesheet: any) => {
			if (
				String(visitTimesheet?.staff_id) === String(timesheetData?.staff_id) &&
				visitTimesheet.date === timesheetData?.date &&
				visitTimesheet?.status !== 'Deleted' &&
				visitTimesheet?.status !== 'Inactive'
			) {
				return visitTimesheet
			}
		}
	) || []

	const sortedTimeSheetsByTimeIn = [...filteredVisitTimesheets].sort(
		(a: any, b: any) => {
			return moment(a.job_start_time, 'HH:mm').diff(
				moment(b.job_start_time, 'HH:mm')
			)
		}
	)

	const mappedVisitTimesheets = sortedTimeSheetsByTimeIn.map(
		(visitTimesheet: any, index: number) => {
			const newVisitTimesheet = { ...visitTimesheet }
			if (!newVisitTimesheet.job_start_time) {
				if (index === 0) {
					newVisitTimesheet.job_start_time =
						timesheetData.time_on || newVisitTimesheet.time_in
				} else {
					newVisitTimesheet.job_start_time =
						sortedTimeSheetsByTimeIn[index - 1].time_off
				}
				newVisitTimesheet.index = index
			}
			return newVisitTimesheet
		}
	)
	console.log({mappedVisitTimesheets})

	if (mappedVisitTimesheets.length > 0 && timesheetData.time_off) {
		mappedVisitTimesheets[mappedVisitTimesheets.length - 1].time_off = timesheetData.time_off;
	}

	const rowData = mappedVisitTimesheets
		.filter(
			(timesheet: { status: string }) =>
				timesheet?.status !== 'Deleted' && timesheet?.status !== 'Inactive'
		)
		.map((timesheet: any) => {
			const visit: any = timesheet.visit
			const job: any = timesheet?.job
			const staff: any = timesheet?.staff
			const start_time = timesheet.job_start_time
				? moment(timesheet.job_start_time, 'HH:mm')
				: moment(timesheet.time_in, 'HH:mm')
			const end_time = moment(timesheet.time_off, 'HH:mm')
			const difference = end_time.diff(start_time, 'hours', true) || 0
			const mappedRow = {
				record_id: timesheet?.id,
				staff_id: staff?.id,
				id:
					String(job?.id) +
					'_' +
					String(visit?.id) +
					'_' +
					String(staff?.id) +
					'_' +
					String(timesheet?.id),
				visit_id: visit?.id,
				name: staff?.staff_name,
				job_start_time: timesheet.job_start_time || timesheet?.time_in,
				pre_start_time: timesheet?.time_in,
				end_time: timesheet?.time_off,
				date: timesheet?.date,
				job: job?.job_num,
				description: job?.descriptionOfQuote,
				client_name: job?.clientData?.client_name,
				site: job?.site,
				difference: difference,
				confined_spaces: job?.job_type === 'Confined Spaces' ? true : false,
				timesheet_status: timesheet?.status,
				task_ids: visit ? visit.task_ids : timesheet.task_ids || [],
				visit_type: visit ? visit.type : 'Other',
				earning_rate: timesheet?.earning_rate,
				had_lunch_break: timesheet?.had_lunch_break ? 'Yes' : 'No',
			}
			return mappedRow
		})

	console.log({rowData});

	const getVisitTaskName = (task_ids: string[]) => {
		const taskIds = [...task_ids]

		if (taskIds) {
			const taskNames = []
			for (const taskId of taskIds) {
				const { data } = JobsServices.useJobTaskById(Number(taskId))
				const taskName = data
					? `${data.zone_label} - ${data.type} - ${data.description}`
					: null
				taskNames.push(taskName)
			}
			return taskNames ? taskNames.join(', ') : 'None'
		}

		return 'None'
	}

	const columns = [
		{
			field: 'date',
			header: 'Date',
			body: (row: { date: Date }) => {
				return row.date
					? moment(row.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
					: ''
			},
		},
		{
			field: 'visit_id',
			header: 'Visit #',
			body: (row: { visit_id: number }) => {
				return row.visit_id ? (
					<Badge type={'Green'} text={String(row.visit_id)} />
				) : (
					<Badge type={'Orange'} text={'No Visit'} />
				)
			},
		},
		{
			field: 'name',
			header: 'Name',
		},
		{
			field: 'job',
			header: 'Job',
		},
		{
			field: 'task',
			header: 'Task',
			body: (row: { task_ids: string[] }) => getVisitTaskName(row.task_ids),
		},
		{
			field: 'visit_type',
			header: 'Type',
		},
		{ field: 'client_name', header: 'Client' },
		{
			field: 'site',
			header: 'Site',
		},
		{ field: 'job_start_time', header: 'Job Start' },
		{ field: 'pre_start_time', header: 'Pre Start' },
		{ field: 'end_time', header: 'Time Off' },
		{ field: 'difference', header: 'Hours' },
		{ field: 'had_lunch_break', header: 'Lunch Break' },
		{ field: 'earning_rate', header: 'Earning Rate' },
		{
			field: 'confined_spaces',
			header: 'Confined Spaces?',
			body: (row: { confined_spaces: boolean }) => {
				return row.confined_spaces ? (
					<Badge type={'Warning'} text={'Confined Space'} />
				) : (
					<Badge type={'Green'} text={'No'} />
				)
			},
		},
		{ field: 'timesheet_status', header: 'Timesheet Status' },
		{
			field: 'operations',
			header: 'Operations',
			body: (row: any) => (
				<div className="flex-row space-y-2 mt-2 mb-2 items-start mr-4">
					<div className="flex items-center">
						<button
							onClick={async () => {
								const timeSheet = visitTimesheets?.find(
									(timeSheet: { id: any }) => timeSheet.id === row.record_id
								)
								console.log(row)
								await updateVisitTimesheet({ ...timeSheet, status: 'Approved' })
							}}
							className="flex items-center text-green-400 disabled:opacity-70 disabled:cursor-not-allowed"
							disabled={row.timesheet_status === 'Approved'}>
							<CheckIcon className="h-4 w-4 mx-2" />
							<span>Mark Approved</span>
						</button>
						</div>
						<div className="flex items-center">
						<Link
									to={{
										pathname: AppRoutes.privateRoutes.visitTimesheetsEdit.replace(
											':id',
											row.record_id
										),
									}}
									className='flex items-center text-orange-400 disabled:opacity-70 disabled:cursor-not-allowed'
									state={{ background: location, name: 'editVisitTimesheet' }}>
									<PencilIcon className="h-4 w-4 mx-2" />
									<span>Edit</span>
								</Link>
					</div>
				</div>
			),
		},
	]

	const customHeaderTemplate = (data: { visit_id: number; date: string }) =>
		data.visit_id ? (
			<span className="text-gray-900 font-bold">
				Visit #{data?.visit_id} on {data?.date}
			</span>
		) : (
			<span className="text-gray-900 font-bold">No Visits</span>
		)

	function calcHours() {
		const start = moment(timesheetData.time_on, 'HH:mm')
		const finish = moment(timesheetData.time_off, 'HH:mm')

		if (timesheetData.time_off && finish) {
			const duration = moment.duration(finish.diff(start))
			const hours = duration.asHours()
			if (hours < 5) {
				return hours // No lunch break for less than 5 hours
			}
			return hours - Number(timesheetData.lunch_break) / 60
		}
		return null
	}

	const totalVisitHours = () => {
		let total = 0

		for (let data of rowData) {
			total += data.difference
		}

		return total
	}

	const allVisitTimesheetsApproved = rowData.every(
		(timesheet) => timesheet.timesheet_status === 'Approved'
	)

	const footerGroup = (
		<ColumnGroup>
			<Row>
				<Column
					footer="Total Hours:"
					colSpan={11}
					footerStyle={{
						textAlign: 'right',
						background: '#f3f4f6',
						fontWeight: 'normal',
					}}
				/>
				<Column
					footer={totalVisitHours}
					footerStyle={{
						background: '#f3f4f6',
					}}
				/>
			</Row>
		</ColumnGroup>
	)

	return (
		<div className="w-full mx-auto mt-8 mb-28">
			<div className="flex justify-end mr-8 mb-4">
				<ApproveTimesheet
					timesheets={[{ id: Number(id), status: timesheetData.status, staffData: timesheetData.staffData }]}
					disabled={timesheetData.status === 'Approved' || !allVisitTimesheetsApproved}
				/>
			</div>
			{timesheetData && (
				<TwoColumnDetails
					heading="Payroll Timesheet Details"
					editBtn="Edit Payroll Timesheet"
					isEditable={false}>
					<Section title="Date" content={timesheetData.date} />
					<Section title="Status" content={timesheetData.status} />

					<Section title="Lunch Break" content={timesheetData.lunch_break} />
					<Section title="" content={''} />
					<Section title="" content={''} />
					<Section title="" content={''} />
					<Section title="Time On" content={timesheetData.time_on} />
					<Section title="Actual Start" content={timesheetData.actual_start} />
					<Section title="Time Off" content={timesheetData.time_off} />
					<Section
						title="Actual Finish"
						content={timesheetData.actual_finish}
					/>
					<Section title="" content={''} />
					<Section title="" content={''} />
					<Section title="Hours" content={calcHours()} />
					<Section title="" content={''} />
					<Section title="Comments" content={timesheetData.comments} />
					<Section title="" content={''} />
					<Section title="Overnight Allowance Claimed?" content={timesheetData.overnight_allowance_claimed} />
					<Section title="Claim Reason" content={timesheetData.claim_reason} />
					</TwoColumnDetails>
			)}
			{
				<Table
					columns={columns}
					data={rowData}
					groupBy="visit_id"
					isLoading={false}
					title="Visits Timesheets"
					headerTemplate={customHeaderTemplate}
					footerTemplate={footerGroup}
					disableButtons
				/>
			}
		</div>
	)
}
