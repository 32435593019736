export const rateDescription = [
	{
		rate: 'Standard Time',
		description: 'Erect and Dismantle',
	},
	{
		rate: '1.2 (Confined space work, AICA, BT Mining and Fishwells)',
		description: 'Erect and Dismantle',
	},
	{
		rate: 'Overtime rate - 1.5',
		description: 'Erect and Dismantle',
	},
	{
		rate: 'M2 Rate - Erect and Dismantle',
		description: 'Erect and Dismantle',
	},
	{
		rate: 'Inspection',
		description: 'Inspection',
	},
	{
		rate: 'Shrink wrap per m2',
		description: 'Supply and install shrinkwrap',
	},
	{
		rate: 'Scrim per m2',
		description: 'Supply and install scrim',
	},
	{
		rate: 'Kedar Temporary Roof System (Per m2 of Roof Area)',
		description: 'Supply and install temporary roof',
	},
	{
		rate: 'Safety Nets (Includes Labour Per m2)',
		description: 'Supply and install safety net',
	},
	{
		rate: 'Engineering Report - PC Sum',
		description: 'Engineering Report',
	},
	{
		rate: 'Traffic Management Plan - PC Sum',
		description: 'Traffic Management Supply',
	},
	{
		rate: 'Traffic Management Supply - Per day',
		description: 'Traffic management per day',
	},
	{
		rate: 'Crane',
		description: 'Crane',
	},
	{
		rate: 'Rubbish Chute',
		description: 'Supply and install rubbish chute',
	},
	{
		rate: 'Minimum Approach Distance Hoarding',
		description: 'Supply and Install Minimum Approach Distance Hoarding',
	},
]