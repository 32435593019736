import {
	DateSelect,
	Dropdown,
	Input,
	MultiSelect,
	SideModal,
	Spinner,
	TextArea,
	TimeSelect,
} from 'common'
import { useFormik } from 'formik'
import { yes_no_options } from 'models'
import moment from 'moment'
import { useEffect } from 'react'
import { StaffServices, TimesheetServices } from 'services'
import { ICreateTimesheetPayload } from 'services/timesheets'
import { OptionsForDropdown, OptionsForDropdownFilter } from 'utilities'
import * as Yup from 'yup'

interface IProps {
	heading: string
	setOpen: (open: boolean) => void
	open: boolean
}

interface ITimesheet {
	date: string
	job_id: number | null
	time_on: string
	time_off: string
	hours?: number
	comments: string
	status: string
	staff_ids: number[]
	timesheet_id?: string
	approved_by?: string
	actual_start: string
	actual_finish: string
	exported?: string
	visitTimeSheetId?: string
	lunch_break: number
	overnight_allowance_claimed: string
	claim_reason: string
}

export const CreateTimesheet = ({ heading, setOpen, open }: IProps) => {
	const { createTimesheet } = TimesheetServices.useCreateTimesheet()
	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()

	const initialValues: ITimesheet = {
		date: '',
		job_id: null,
		time_on: '',
		time_off: '',
		comments: '',
		status: '',
		staff_ids: [],
		actual_start: '',
		actual_finish: '',
		lunch_break: 30,
		overnight_allowance_claimed: 'No',
		claim_reason: '',
	}

	const validationSchema = Yup.object({
		lunch_break: Yup.number()
			.required('Lunch Break is required')
			.typeError('Lunch Break must be a number of minutes')
			.min(0, 'Lunch Break must be greater than 0')
			.max(120, 'Lunch Break must be less than 120'),
		time_on: Yup.string().required('Start Time is required'),
		time_off: Yup.string().required('Finish Time is required'),
		date: Yup.string().required('Date is required'),
		staff_ids: Yup.array().of(Yup.number()).required('At least one staff member is required'),
		status: Yup.string().required('Status is required'),
		claim_reason: Yup.string().when('overnight_allowance_claimed', (overnight_allowance_claimed, schema) => {
			return overnight_allowance_claimed[0] === 'Yes'
				? schema.required('Claim Reason is required when claiming overnight allowance')
				: schema.nullable();
		}),
	});
	

	const initialErrors = {
		time_on: 'Start Time is required',
		time_off: 'Finish Time is required',
		date: 'Date is required',
		staff_ids: 'At least one staff member is required',
		status: 'Status is required',
	}

	const formik = useFormik({
		initialValues,
		validationSchema,
		initialErrors,
		enableReinitialize: false,
		onSubmit: async (values, { setSubmitting }) => {
			const payload: ICreateTimesheetPayload = 
				{
					date: values.date,
					job_id: values.job_id,
					time_on: values.time_on,
					time_off: values.time_off,
					comments: values.comments,
					status: values.status,
					hours: values.hours || 0,
					staff_ids: values.staff_ids,
					actual_start: values.time_on,
					actual_finish: values.time_off,
					lunch_break: values.lunch_break,
					timesheet_id: '',
					approved_by: '',
					exported: 'No',
					visitTimeSheetId: '',
					overnight_allowance_claimed: values.overnight_allowance_claimed,
					claim_reason: values.claim_reason,
				}
			createTimesheet(payload)
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		if (formik.values.overnight_allowance_claimed === 'No') {
			formik.setFieldValue('claim_reason', '')
		}
	}, [formik.values.overnight_allowance_claimed])

	const handleUpdate = (field: string, value: any) => {
		switch (field) {
			case 'time_on':
				formik.setFieldValue('actual_start', value)
				if (formik.values.time_off && formik.values.time_off !== '') {
					const timeOnAsMoment = moment(value, 'HH:mm')
					const timeOffAsMoment = moment(formik.values.time_off, 'HH:mm')
					const timeDifferenceInHours = timeOffAsMoment.diff(
						timeOnAsMoment,
						'hours',
						true
					)
					formik.setFieldValue('hours', timeDifferenceInHours)
				}
				break
			case 'time_off':
				formik.setFieldValue('actual_finish', value)
				if (formik.values.time_on && formik.values.time_on !== '') {
					const timeOnAsMoment = moment(formik.values.time_on, 'HH:mm')
					const timeOffAsMoment = moment(value, 'HH:mm')
					const timeDifferenceInHours = timeOffAsMoment.diff(
						timeOnAsMoment,
						'hours',
						true
					)
					formik.setFieldValue('hours', timeDifferenceInHours)
				}
				break
		}
		return formik.setFieldValue(field, value)
	}

	// console.log(formik.values, 'values')
	// console.log(formik.errors, 'errors')

	if (staffLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType="create">
				{/* <div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="staff_id"
							label="Staff Member"
							options={OptionsForDropdown(staffData, 'id', 'staff_name')}
							value={formik.values.staff_id}
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							error={formik.errors.staff_id}
						/>
					</div>
				</div> */}
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<MultiSelect
							id="staff_ids"
							label="Staff Members"
							options={OptionsForDropdownFilter(
								staffData,
								'id',
								['staff_name'],
								'status',
								'Active'
							)}
							value={formik.values.staff_ids}
							onChange={formik.setFieldValue}
							error={formik.errors.staff_ids}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<DateSelect
							title="Date"
							id="date"
							value={formik.values?.date}
							onChange={formik.setFieldValue}
							error={formik.errors.date}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<TimeSelect
							id="time_on"
							title="Start Time"
							onChange={handleUpdate}
							value={formik.values.time_on}
							error={formik.errors.time_on}
							isClearable
						/>
					</div>
					<div className="w-1/2">
						<TimeSelect
							id="time_off"
							title="Finish Time"
							onChange={handleUpdate}
							value={formik.values.time_off}
							error={formik.errors.time_off}
							isClearable
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							id="lunch_break"
							title="Lunch Break (minutes)"
							placeholder="e.g 30"
							type="number"
							error={formik.errors.lunch_break}
							value={formik.values.lunch_break}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="hours"
							title="Hours"
							placeholder="Calculated automatically"
							type="number"
							error={formik.errors.hours}
							value={formik.values.hours || 0}
							disabled
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<TextArea
							id="comments"
							title="Comments"
							rows={4}
							type="text"
							error={formik.errors.comments}
							value={formik.values.comments}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="status"
							label="Status"
							options={[
								{ value: 'Pending', label: 'Pending' },
								{ value: 'Approved', label: 'Approved' },
							]}
							value={formik.values.status}
							onChange={formik.setFieldValue}
							error={formik.errors.status}
						/>
					</div>
				</div>
				<div className="flex flex-col justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							label="Overnight Allowance Claimed?"
							id="overnight_allowance_claimed"
							options={yes_no_options}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							value={formik.values.overnight_allowance_claimed}
							error={formik.errors.overnight_allowance_claimed}
						/>
					</div>
					{formik.values.overnight_allowance_claimed === 'Yes' && (
						<div className="w-full">
							<TextArea
								id="claim_reason"
								title="Claim Reason"
								rows={4}
								type="text"
								error={formik.errors.claim_reason}
								value={formik.values.claim_reason}
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
							/>
						</div>
					)}
				</div>
			</SideModal>
		</>
	)
}
