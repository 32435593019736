import { useContext, useEffect, useRef } from 'react'
import { Button, Spinner, Tabs, TextArea } from 'common'
import {
	SimpleQuoteServices,
	JobsServices,
	StaffServices,
	ContactServices,
	ClientServices,
} from 'services'
import {
	SectionsForm,
	RatesForm,
	SimpleQuoteLinesForm,
	SimpleQuoteAdditionalLines,
	Totals,
} from '../FormComponents'
import { useState } from 'react'
import { GeneralInfo } from '../FormComponents/GeneralInfo'
import { RatesAdmin } from '../FormComponents/Rates/RatesAdmin'
import SiteFiles from '../FormComponents/SiteFiles/SiteFiles'
import { NotificationsContext } from 'context/notifications/toastContext'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'
import { FastSpinner } from 'common/Spinner/FastSpinner'

interface FormProps {
	quoteID?: number
}

export const SimpleForm = ({ quoteID }: FormProps) => {
	const { showMultipleErrors } = useContext(NotificationsContext)
	const formRef = useRef<HTMLDivElement | null>(null)
	const [tabIndex, setTabIndex] = useState(0)

	const { data: quote_data, isLoading: quote_loading } =
		SimpleQuoteServices.useQuoteById(quoteID)

	const { data: quote_lines, isLoading: quote_lines_loading } =
		SimpleQuoteServices.useQuoteLinesById(quoteID)

	const { data: quote_addons, isLoading: quote_addons_loading } =
		SimpleQuoteServices.useQuoteAddonsById(quoteID)

	const { data: quote_zones, isLoading: quote_zones_loading } =
		SimpleQuoteServices.useQuoteZonesById(quoteID)

	const { data: quote_files, isLoading: quote_files_loading } =
		SimpleQuoteServices.useQuoteFilesById(quoteID)

	const { data: quote_rates, isLoading: quote_rates_loading } =
		SimpleQuoteServices.useQuoteRatesById(quoteID)

	const { data: jobs_data, isLoading: jobs_loading } = JobsServices.useJobs()

	const userState = useSelector((store: AppStore) => store.user)
	const clientsDataHook = ClientServices.useClients()
	const clientContactsDataHook = ContactServices.useContacts()
	const staffDataHook = StaffServices.useStaff()

	useEffect(() => {
		if (formik && staffDataHook.data) {
			const defaultEstimator =
				staffDataHook.data?.find((staff) => staff.email === userState.email)
					?.id || 0
			formik.setFieldValue('estimator', defaultEstimator)
		}
	}, [staffDataHook.data])

	const {
		formik,
		quoteLinesHandlers,
		quoteAdditionalLinesHandlers,
		ratesHandlers,
		companyRatesHandlers,
		zonesHandlers,
		setBackToMainTable,
		itemsTabs,
	} = SimpleQuoteServices.useSimpleFormHandler({
		quote_id: quoteID,
		quote_data,
		quote_lines,
		quote_addons,
		quote_rates,
		quote_zones,
		quote_files,
	})

	if (
		quote_loading ||
		quote_lines_loading ||
		quote_addons_loading ||
		quote_zones_loading ||
		quote_rates_loading ||
		quote_files_loading ||
		jobs_loading
	) {
		return <Spinner />
	}

	return (
		<>
			<div className="mx-4 mb-10" ref={formRef}>
				<div className="w-full">
					<div className="flex">
						<div className="lg:w-4/6 md:w-full sm:w-full">
							<GeneralInfo
								formik={formik}
								jobs_data={jobs_data}
								staffDataHook={staffDataHook}
								clientContactsDataHook={clientContactsDataHook}
								clientsDataHook={clientsDataHook}
							/>
						</div>
						<div className="w-full">
							<div className="w-4/6 mx-auto">
								<div className="flex justify-start mb-4">
									<Tabs
										tabIndex={tabIndex}
										setTabIndex={setTabIndex}
										tabs={itemsTabs}
									/>
								</div>
								{tabIndex === 0 && (
									<SectionsForm
										sections={formik.values.zones}
										sectionsHandlers={zonesHandlers}
										errors={formik.errors}
									/>
								)}
								{tabIndex === 1 && (
									<RatesForm
										rates={formik.values.rates}
										ratesHandlers={ratesHandlers}
									/>
								)}
								{tabIndex === 2 && (
									<RatesAdmin
										rates={formik.values.company_rates!}
										ratesHandlers={companyRatesHandlers}
									/>
								)}
							</div>
						</div>
					</div>

					<>
						<SimpleQuoteLinesForm
							zones={formik.values.zones}
							rates={formik.values.rates}
							quoteLines={formik.values.quote_lines}
							quoteLinesHandlers={quoteLinesHandlers}
							isNewQuote={quoteID ? false : true}
						/>

						<SimpleQuoteAdditionalLines
							zones={formik.values.zones}
							rates={formik.values.rates}
							additionalLines={formik.values.quote_additional_lines}
							additionalLinesHandlers={quoteAdditionalLinesHandlers}
						/>

						<SiteFiles
							setFieldValue={formik.setFieldValue}
							values={formik.values}
						/>

						<div className="w-3/6">
							<TextArea
								title="Additional Conditions"
								id="terms"
								placeholder="Additional Conditions"
								handleBlur={formik.handleBlur}
								handleChange={formik.handleChange}
								type="text"
								value={formik.values.terms}
								error={formik.errors.terms}
								rows={10}
							/>
						</div>

						<Totals
							erectDismantleTotal={formik.values.erectDismantleTotal}
							additionalTotal={formik.values.additionalTotal}
							weekTotal={formik.values.weekTotal}
							total={formik.values.total}
						/>

						{quoteID && (
							<div className="pl-2 mt-6 flex space-x-4">
								<Button
									onClick={() => {
										setBackToMainTable(true)
										formik.handleSubmit()
									}}
									type="submit"
									form="quoteForm"
									//isLoading={quoteCreating}
									size="sm"
									variant={'primary'}>
									Save & Exit
								</Button>
								<Button
									onClick={() => formik.handleSubmit()}
									type="submit"
									form="quoteForm"
									size="sm"
									variant={'primary'}>
									Save & View
								</Button>
							</div>
						)}
						{!quoteID && (
							<div className="pl-2 mt-6">
								<Button
									onClick={() => {
										formik.handleSubmit()
										if (
											formik.errors &&
											Object.keys(formik.errors).length > 0 &&
											formRef.current
										) {
											if ('zones' in formik.errors) {
												delete formik.errors['zones']
												formik.errors.zones = 'Sections are required'
												setTabIndex(0)
											}
											const messages: string[] = ([] as string[]).concat(
												...(Object.values(formik.errors)
													.map((error) =>
														Array.isArray(error) ? error : [error]
													)
													.flat() as string[])
											)

											console.log('formik.errors', formik.errors)
											formRef.current.scrollIntoView({
												behavior: 'smooth',
												block: 'start',
											})

											showMultipleErrors(messages)
										}
									}}
									type="submit"
									form="quoteForm"
									size="sm"
									disabled={formik.isSubmitting}
									variant={'primary'}>
									<div className="flex items-center">
										{formik.isSubmitting ? (
											<div className="h-6 w-6 mr-2">
												<FastSpinner />
											</div>
										) : null}
										Create-Save Quote
									</div>
								</Button>
							</div>
						)}
					</>
				</div>
			</div>
		</>
	)
}
