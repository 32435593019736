import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'

export const FastSpinner = () => {
	return (
		<div className="w-full h-full flex justify-center items-center">
			<ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
		</div>
	)
}
