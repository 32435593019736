import { DateSelect, Dropdown, Input, SideModal, Spinner } from 'common'
import { useFormik } from 'formik'
import moment from 'moment'

import { ClientServices, ContactServices } from 'services'
import * as Yup from 'yup'

const yesNoOptions = [
	{
		value: 'Yes',
		label: 'Yes',
	},
	{
		value: 'No',
		label: 'No',
	},
]

interface IProps {
	client_id?: string | number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

const statusOptions = [
	{ label: 'Active', value: 'Active' },
	{ label: 'Inactive', value: 'Inactive' },
]

interface createClientRow {
	client_name: string
	email: string
	phone: string
	status: string
	main_contact: string
	contactName: string
	contactEmail: string
	contactphone: string
	credit_check: string
	credit_check_who: string
	credit_check_when: string
}

export const CreateClientsForm = ({
	client_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data, isLoading } = ClientServices.useClientById(client_id)
	const { createClient } = ClientServices.useCreateClients()
	const { updateClient } = ClientServices.useUpdateClients()
	const { createContact } = ContactServices.useCreateContact()

	const initialValues: createClientRow = {
		client_name: data?.client_name || '',
		email: data?.email || '',
		phone: data?.phone || '',
		status: data?.status || 'Active',
		main_contact: data?.main_contact || null,
		contactName: data?.contactName || '',
		contactEmail: data?.contactEmail || '',
		contactphone: data?.contactphone || '',
		credit_check: data?.credit_check || 'No',
		credit_check_who: data?.credit_check_who || '',
		credit_check_when:
			data?.credit_check_when !== 'Invalid date' &&
			data?.credit_check_when !== ' ' &&
			data?.credit_check_when
				? moment(data?.credit_check_when, 'DD/MM/YYYY').format('DD/MM/YYYY')
				: '',
	}

	const validationSchema = Yup.object({
		client_name: Yup.string().required('Client Name is required'),
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
		phone: Yup.string(),
		contactName: Yup.string().required('Main Contact Name is required'),
		contactEmail: Yup.string()
			.email('Invalid email address')
			.required('Main Contact Email is required'),
		contactphone: Yup.string(),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			const {
				client_name,
				email,
				phone,
				status,
				credit_check,
				credit_check_when,
				credit_check_who,
			} = values

			const ClientData = {
				client_name,
				email,
				phone,
				status,
				credit_check,
				credit_check_who,
				credit_check_when,
			}

			const clientCreatedResult = await createClient(ClientData)
			if(!clientCreatedResult.id)
			{
				console.error('Error creating client')
				return
			}
			const { contactName, contactEmail, contactphone } = values

			const ContactData = {
				name: contactName,
				email: contactEmail,
				phone: contactphone,
				status: 'Active',
				client_id: Number(clientCreatedResult.id),
			}
			const resultContact = await createContact(ContactData)
			console.log(resultContact)
			const newClientUpdateData = {
				...clientCreatedResult.client_data,
				main_contact: resultContact.data.id,
			}
			console.log(newClientUpdateData)
			const updatedContact = await updateClient(newClientUpdateData, clientCreatedResult.id)
			console.log(updatedContact)

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (client_id && isLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center">
					<Input
						title="Client Name"
						id="client_name"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.client_name}
						placeholder=""
						error={formik.errors.client_name}
					/>
				</div>
				<div className="flex items-center">
					<Input
						title="Contact #"
						id="phone"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.phone}
						placeholder=""
						error={formik.errors.phone}
					/>
					<Input
						title="Email"
						id="email"
						type="text"
						handleChange={formik.handleChange}
						handleBlur={formik.handleBlur}
						value={formik.values.email}
						placeholder=""
						error={formik.errors.email}
					/>
				</div>
				<div className="flex items-center">
					<Dropdown
						label="Status"
						id="status"
						options={statusOptions}
						onChange={formik.setFieldValue}
						onBlur={formik.setFieldTouched}
						value={formik.values.status}
						error={formik.errors.status}
					/>
				</div>

				<>
					<div className="flex items-center">
						<Input
							title="Contact Name"
							id="contactName"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.contactName}
							placeholder=""
							error={formik.errors.contactName}
						/>
					</div>
					<div className="flex items-center">
						<Input
							title="Contact #"
							id="contactphone"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.contactphone}
							placeholder=""
							error={formik.errors.contactphone}
						/>
						<Input
							title="Email"
							id="contactEmail"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.contactEmail}
							placeholder=""
							error={formik.errors.contactEmail}
						/>
					</div>
					<div>
						<Dropdown
							label="Credit Check Completed?"
							id="credit_check"
							value={formik.values.credit_check}
							error={formik.errors.credit_check}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={yesNoOptions}
						/>
					</div>
					{formik.values.credit_check === 'Yes' ? (
						<div className="flex items-center">
							<Input
								title="By Who?"
								id="credit_check_who"
								placeholder=""
								type="text"
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
								value={formik.values.credit_check_who}
								error={formik.errors.credit_check_who}
							/>
							<DateSelect
								title="When?"
								id="credit_check_when"
								value={formik.values.credit_check_when}
								error={formik.errors.credit_check_when}
								onChange={formik.setFieldValue}
							/>
						</div>
					) : (
						<></>
					)}
				</>
			</SideModal>
		</>
	)
}
