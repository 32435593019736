import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { useQuery } from '@tanstack/react-query'
import { AppRoutes } from 'config'

export const useAddressesByClientId = (client_id?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAddressesByClientID = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverAddressRoutes.getAddressesByClientID,
				client_id
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting Addreses')
			throw new Error('Something went wrong getting Addresses')
		}
	}

	if (!client_id) {
		return { data: [], isLoading: false, error: null }
	}

	const { data, isLoading, error } = useQuery(
		['addresses', `client_id_${client_id}`], 
		fetchAddressesByClientID
	)

	return { data, isLoading, error }
}

interface APIAddressDTO {
	client_id: number
	street_number: string
	route: string
	sublocality: string
	locality: string
	country: string
	postal_code: string
	formatted_address: string
}

export const useAddresses = () => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAddresses = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverAddressRoutes.getAddresses,
			)
			return response.data as APIAddressDTO[]
		} catch (error) {
			showError('Something went wrong getting Addreses')
			throw new Error('Something went wrong getting Addresses')
		}
	}

	const { data, isLoading, error } = useQuery(
		['addresses'],
		fetchAddresses
	)

	return { data, isLoading, error }
}

export const useAddressById = (address_id?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAddressByID = async () => {
		try {
			const response = await getRequest(
				AppRoutes.serverAddressRoutes.getAddressById,
				address_id
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting Address')
			throw new Error('Something went wrong getting Address')
		}
	}

	if (!address_id) {
		return { data: [], isLoading: false, error: null }
	}

	const { data, isLoading, error } = useQuery(['addresses', address_id], fetchAddressByID)

	return { data, isLoading, error }
}
