import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useQuoteZonesByToken = (token: string) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteZonesByToken = async (token: string) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.getQuoteZonesByToken,
				token
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting quote zones')
			throw new Error('Something went wrong getting quote zones')
		}
	}

	if (!token) {
		return {
			data: undefined,
			isLoading: undefined,
			error: undefined,
			fetchQuoteZonesById: fetchQuoteZonesByToken,
		}
	}

	const { data, isLoading, error } = useQuery(['quote_zones', token], () =>
		fetchQuoteZonesByToken(token)
	)

	return { data, isLoading, error }
}


export const useQuoteZonesById = (quoteId?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteZonesById = async (quoteId: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.getQuoteZonesById,
				quoteId
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting quote zones')
			throw new Error('Something went wrong getting quote zones')
		}
	}

	if (!quoteId) {
		return {
			data: undefined,
			isLoading: undefined,
			error: undefined,
			fetchQuoteZonesById,
		}
	}

	const { data, isLoading, error } = useQuery(['quote_zones', quoteId], () =>
		fetchQuoteZonesById(quoteId)
	)

	return { data, isLoading, error, fetchQuoteZonesById }
}
