import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'

export const useDeleteServiceRateById = () => {
	const { showError } = useContext(NotificationsContext)
	const { deleteRequest } = useApi()

	const deleteServiceRateById = async (id: number) => {
		try {
			const response = await deleteRequest(
				AppRoutes.serverQuoteRoutes.deleteServiceRatesById,
				id
			)
			return response.data
		} catch (error) {
			showError('Something went wrong deleting service rate')
			throw new Error('Something went wrong deleting service rate')
		}
	}

	return { deleteServiceRateById }
}
