import { PencilIcon } from '@heroicons/react/24/solid'
import { Spinner, Table } from 'common'
import { AppRoutes } from 'config'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { InvoiceServices, ScaffoldRegisterServices } from 'services'
import { useGetTagByJobId } from 'services/ScaffoldRegister'
import { numberFormat } from 'utilities'
import { WeeklyHireInvoiceCreateForm } from './CreateWeeklyHire'

interface WeeklyHireTableProps {
	job_id?: number
}

export const WeeklyHireTable = ({ job_id }: WeeklyHireTableProps) => {
	const location = useLocation()
	const [openCreate, setOpenCreate] = useState(false)
	const { data, isLoading } = InvoiceServices.useWeeklyHireInvoicesByJobId(job_id);
  const { data: scaffoldsData, isLoading: scaffoldsLoading } = useGetTagByJobId(job_id);

	if (isLoading || scaffoldsLoading) {
		return <Spinner />
	}

	console.log(data)
	console.log('.......')

	const rowData = data?.map((item: any) => {
		const total_dimensions = scaffoldsData?.reduce(
			(accumulator: number, scaffold: any) => {
				if (
					scaffold.handover_doc &&
					Number(scaffold.task_id) === item.task_id
				) {
					return accumulator + Number(scaffold.total_dimensions)
				}
				return accumulator
			},
			0
		)
		return {
			zone_label: item.zone_label,
			type: item.type,
			description: item.taskData?.description || '',
			on_hire: item.on_hire,
			erect_percent: item.erect_percent,
			date_on_hire: item.date_on_hire,
			completed_date: item.completed_date,
			days_on_hire: item.days_on_hire,
			weekly_hire_rate: item.jobData.is_dayworks ? (Number(item?.taskData?.actual_square_meters) * item.sqm_rate) || '' : item.weekly_hire_rate,
			handover_url: item.handover_url,
			id: item.id,
			actual_square_meters: item?.taskData?.actual_square_meters ?? 0,
			status: item.status,
			sqm_rate: item.sqm_rate,
			invoice_number: item.xeroReference,
			total: item.total,
		}
	})

	const columns = [
		{ field: 'zone_label', header: 'Section' },
		{ field: 'type', header: 'Type' },
		{
			field: 'description',
			header: 'Description',
		},
		{
			field: 'on_hire',
			header: 'On Hire',
		},
		{
			field: 'actual_square_meters',
			header: 'Installed SQM',
		},
		{
			field: 'erect_percent',
			header: '% Erect',
		},
		{
			field: 'date_on_hire',
			header: 'Date On Hire',
		},
		{
			field: 'completed_date',
			header: 'Completed Date',
		},
		{
			field: 'days_on_hire',
			header: 'Days on Hire',
		},
		{
			field: 'sqm_rate',
			header: 'SQM Rate',
			body: (row: { sqm_rate: number }) => {
				return numberFormat.format(row.sqm_rate || 0)
			},
		},
		{
			field: 'weekly_hire_rate',
			header: 'Weekly Hire Rate',
			body: (row: { weekly_hire_rate: number }) => {
				return numberFormat.format(row.weekly_hire_rate || 0)
			},
		},
		{
			field: 'total',
			header: 'Total',
			body: (row: { total: number }) => {
				return numberFormat.format(row.total || 0)
			},
		},
		{
			field: 'invoice_number',
			header: 'Invoice Number',
		},
		{
			field: 'handover_url',
			header: 'Handover Certificate',
			body: (row: { handover_url: string }) => {
				if (row.handover_url) {
					return (
						<a href={row.handover_url} target="_blank" rel="noreferrer">
							Link
						</a>
					)
				}
				return <></>
			},
		},
		{
			field: 'Edit',
			header: 'Edit',
			body: (row: { id: number; status: string }) => {
				if (row.status === 'Pending') {
					return (
						<Link
							to={{
								pathname: AppRoutes.privateRoutes.editInvoice
									.replace(':id', row.id.toString())
									.replace(':invoiceType', 'weeklyHire'),
							}}
							state={{ background: location, name: 'editInvoice' }}>
							<PencilIcon className="h-4 w-4 text-gray-500" />
						</Link>
					)
				}
				return <></>
			},
		},
	]

	
	return (
		<>
			<Table
				columns={columns}
				data={rowData}
				isLoading={isLoading ?? false}
				title="Weekly Hire Invoices"
				ActionName="Create Weekly Hire Invoice"
				setOpen={setOpenCreate}
				disableButtons
			/>
			<WeeklyHireInvoiceCreateForm
				heading="Create Weekly Hire Invoice"
				setOpen={setOpenCreate}
				formType="create"
				open={openCreate}
				job_id={job_id}
			/>
		</>
	)
}
