import React, { useEffect, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { AppConfig } from 'config'

interface AddressProps {
	addressFieldId: string
	addressFieldValue: Address
	handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void
	setFieldValue: (field: string, value: any) => void
}

interface Address {
	full_address: string
	street_number: string
	route: string
	sublocality: string
	locality: string
	country: string
	postal_code: string
}

// street_number,
// route,
// sublocality,
// locality,
// country,
// postal_code

function getValueFromAddressComponent(
	addressComponents: any[],
	type: string
): string {
	const component = addressComponents.find((component) => component.types.includes(type))
	console.log(component)
	return component ? component.long_name : ''
}

function getFullAddress(addressComponents: any[]): string {
	return addressComponents.map((component) => component.long_name).join(', ')
}

export function SearchAddressV2({
	addressFieldId,
	addressFieldValue,
	handleBlur,
	setFieldValue,
}: AddressProps) {
	const googleApiKey = AppConfig.GoogleMapsApiKey
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handlePlaceSelect = (place: any) => {
		const addressComponents = place.address_components
		console.log(addressComponents)
		const newAddress: Address = {
			street_number: getValueFromAddressComponent(addressComponents, 'street_number') || '',
			route: getValueFromAddressComponent(addressComponents, 'route') || '',
			sublocality: getValueFromAddressComponent(addressComponents, 'sublocality') || '',
			locality: getValueFromAddressComponent(addressComponents, 'locality') || '',
			country: getValueFromAddressComponent(addressComponents, 'country') || '',
			postal_code: getValueFromAddressComponent(addressComponents, 'postal_code') || '',
			full_address: getFullAddress(addressComponents) || '',
		}

		setFieldValue(addressFieldId, newAddress)
	}

	return (
		<div className="px-2 py-4 mt-10 sm:mt-0">
			<div className="md:grid md:grid-cols-6 md:gap-6">
				<div className="col-span-6">
					<Autocomplete
						apiKey={googleApiKey}
						placeholder=""
						style={{ width: '100%' }}
						className="autocomplete border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onPlaceSelected={(place) => {
							handlePlaceSelect(place)
						}}
						options={{
							types: ['address'],
							componentRestrictions: { country: 'nz' },
						}}
					/>
				</div>
				{/* <div className="col-span-3">
					<label
						htmlFor='fullAddressInput'
						className="block text-sm font-medium text-gray-700">
						Full Address
					</label>
					<input
						type="text"
						name='fullAddressInput'
						id='fullAddressInput'
						autoComplete="off"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							return false
						}}
						onBlur={handleBlur}
						disabled
						value={addressFieldValue.full_address}
					/>
				</div> */}
				<div className="col-span-3">
					<label
						htmlFor={"streetNumberInput"}
						className="block text-sm font-medium text-gray-700">
						Street Number
					</label>
					<input
						type="text"
						name='streetNumberInput'
						id='streetNumberInput'
						autoComplete="off"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							const newAddress = {
								...addressFieldValue,
								street_number: e.target.value,
							}
							setFieldValue(addressFieldId, newAddress)
						}}
						onBlur={handleBlur}
						value={addressFieldValue.street_number}
					/>
				</div>

				<div className="col-span-3">
					<label
						htmlFor={'routeInput'}
						className="block text-sm font-medium text-gray-700">
						Street Name
					</label>
					<input
						type="text"
						name='routeInput'
						id='routeInput'
						autoComplete="off"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							const newAddress = { ...addressFieldValue, route: e.target.value }
							setFieldValue(addressFieldId, newAddress)
						}}
						onBlur={handleBlur}
						value={addressFieldValue.route}
					/>
				</div>

				<div className="col-span-6 sm:col-span-6 lg:col-span-4">
					<label
						htmlFor="sublocality"
						className="block text-sm font-medium text-gray-700">
						Suburb
					</label>
					<input
						type="text"
						name='sublocalityInput'
						id='sublocalityInput'
						autoComplete="off"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							const newAddress = { ...addressFieldValue, sublocality: e.target.value }
							setFieldValue(addressFieldId, newAddress)
						}}
						onBlur={handleBlur}
						value={addressFieldValue.sublocality}
					/>
				</div>
				<div className="col-span-6 sm:col-span-6 lg:col-span-4">
					<label
						htmlFor="city"
						className="block text-sm font-medium text-gray-700">
						City
					</label>
					<input
						type="text"
						name='localityInput'
						id='localityInput'
						autoComplete="off"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							const newAddress = { ...addressFieldValue, locality: e.target.value }
							setFieldValue(addressFieldId, newAddress)
						}}
						onBlur={handleBlur}
						value={addressFieldValue.locality}
					/>
				</div>
				<div className="col-span-6 sm:col-span-2 lg:col-span-2">
					<label
						htmlFor="postal-code"
						className="block text-sm font-medium text-gray-700">
						Postal Code
					</label>
					<input
						type="text"
						name='postal_codeInput'
						id='postal_codeInput'
						autoComplete="off"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							const newAddress = { ...addressFieldValue, postal_code: e.target.value }
							setFieldValue(addressFieldId, newAddress)
						}}
						onBlur={handleBlur}
						value={addressFieldValue.postal_code}
					/>
				</div>
				<div className="col-span-6 sm:col-span-2 lg:col-span-2">
					<label
						htmlFor="postal-code"
						className="block text-sm font-medium text-gray-700">
						Country
					</label>
					<input
						type="text"
						name='countryInput'
						id='countryInput'
						autoComplete="off"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-9 rounded-md shadow-sm pl-3"
						onChange={(e) => {
							const newAddress = { ...addressFieldValue, country: e.target.value }
							setFieldValue(addressFieldId, newAddress)
						}}
						onBlur={handleBlur}
						value={addressFieldValue.country}
					/>
				</div>
			</div>
		</div>
	)
}
