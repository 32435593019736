import { CheckIcon } from '@heroicons/react/24/solid'
import { Button, ConfirmationDialog } from 'common'
import { VisitTimesheetsServices } from 'services'

interface ApproveVisitTimesheet {
	timesheets: {
		record_id: any
	}[]
}
export const ApproveVisitTimesheet = ({ timesheets }: ApproveVisitTimesheet) => {
	const { updateVisitTimesheet } = VisitTimesheetsServices.useUpdateTimesheetById()
	const handleApproveVisitTimesheets = async () => {
		const selectedTimesheets = timesheets.map(
			(row) => ({
				id: row.record_id,
				status: 'Approved',
			})
		)
		
		await updateVisitTimesheet(selectedTimesheets)
	}

	return (
		<ConfirmationDialog
			icon="info"
			title={'Approve Visit Timesheet'}
			body="Are you sure you wish to approve this visit timesheet?"
			triggerButton={
				<Button
					variant="approve"
					size="sm"
					startIcon={
						<CheckIcon
							className="-ml-0.5 mr-2 h-4 w-4 text-white"
							aria-hidden="true"
						/>
					}>
					Approve Visit Timesheets
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					variant="approve"
					onClick={async () => handleApproveVisitTimesheets()}>
					Approve
				</Button>
			}
		/>
	)
}
