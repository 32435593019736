import { Table } from 'common'
import columns from './Columns'
import { useNotesJobs } from 'services/notes'
import { AppFilesServices } from 'services'
import moment from 'moment'
import { useMemo } from 'react'

interface JobsTableProps {
	id: string | number
	setShowNotesForm: (open: boolean) => void
	setNoteID: (id: string) => void
}

export const JobsTable = ({
	id,
	setShowNotesForm,
	setNoteID,
}: JobsTableProps) => {
	const { data: notes, isLoading: notesLoading } = useNotesJobs(id)
	const { data: files, isLoading: filesLoading } =
		AppFilesServices.useAppFiles()

	const jobFiles = files?.filter((file: any) => file.job_id === id)

	const jobFilesAsNotes = useMemo(() => {
		return jobFiles?.map((file: any) => ({
			id: null,
			fileName: 'Job File',
			fileType: file.file_type,
			fileDescription: file.file_description,
			notes: '',
			fileUrl: file.link,
			createdDate: moment(file.created_at).format('DD/MM/YYYY'),
		}))
	}, [jobFiles])

	const allData = useMemo(() => {
		return [...(notes ?? []), ...(jobFilesAsNotes ?? [])]
	}, [notes, jobFilesAsNotes])

	/*{
			header: 'Created Date',
			field: 'createdDate',
			body: (row: { createdDate: Date }) => {
				return row.createdDate ? moment(row.createdDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
			},
		},
		{
			header: 'File Name',
			field: 'fileName',
		},
		{
			header: 'File Type',
			field: 'fileType',
		},
		{
			header: 'File Description',
			field: 'fileDescription',
		},
		{
			header: 'Notes',
			field: 'notes',
		},
		{
			header: 'File',
			field: 'fileUrl',
			body: (rowData: { fileUrl: string }) => <a href={rowData.fileUrl}>Link</a>,
		},*/

	return (
		<div>
			<Table
				columns={columns({ setShowNotesForm, setNoteID })}
				data={allData}
				isLoading={notesLoading || filesLoading}
				title="Job Notes & Files"
				setShowNotesForm={setShowNotesForm}
			/>
		</div>
	)
}
