import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AddressForm } from 'components/Addresses'

export const EditAddressForm = () => {
	const [openAddressForm, setOpenAddressForm] = useState(true)
	const navigate = useNavigate()
	const { id } = useParams()

	useEffect(() => {
		if (!openAddressForm) {
			navigate(-1)
		}
	}, [openAddressForm])

	return (
		<AddressForm
			formType="update"
			heading="Edit Address"
			open={openAddressForm}
			setOpen={setOpenAddressForm}
			address_id={Number(id)}
		/>
	)
}
