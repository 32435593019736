import { Spinner, Table } from 'common'
import moment from 'moment'
import { JobsServices, TimesheetServices, VisitServices, VisitTimesheetsServices } from 'services'
interface JobQuoteAndActualHoursProps {
	job_id?: number
}
const QuoteAndActualHours = ({ job_id }: JobQuoteAndActualHoursProps) => {
	const { data: tasksData, isLoading: tasksLoading } =
		JobsServices.useJobTask(job_id)
	const { data: visitData, isLoading: visitLoading } =
		VisitServices.useVisitsByJobId(job_id)
	const { data: timesheetsData, isLoading: timesheetsLoading } =
		VisitTimesheetsServices.useVisitTimesheetsByJobId(job_id)
	const { data, isLoading } = TimesheetServices.useTimesheets()

	if (isLoading || visitLoading || tasksLoading || timesheetsLoading) {
		return <Spinner />
	}

	const findStartTime = (staffId: number, date: string) => {
		const entry = data.find(
			(item: { staff_id: number; date: string }) =>
				item.staff_id === staffId && item.date === date
		)
		return entry ? entry.time_on : null
	}

	const sortAndGroupEntries = (data: any) => {
		const groupedEntries: any = {}

		data.forEach((item: { staff: { id: any }; date: any }) => {
			const key = `${item.staff.id}_${item.date}`
			if (!groupedEntries[key]) {
				groupedEntries[key] = []
			}
			groupedEntries[key].push(item)
		})

		Object.keys(groupedEntries).forEach((key) => {
			groupedEntries[key].sort(
				(
					a: { job_start_time: moment.MomentInput },
					b: { job_start_time: moment.MomentInput }
				) => {
					const aJobStartTime = moment(a.job_start_time, 'HH:mm').valueOf()
					const bJobStartTime = moment(b.job_start_time, 'HH:mm').valueOf()
					return aJobStartTime - bJobStartTime
				}
			)
		})

		return groupedEntries
	}

	const updateStartTimeForMultipleEntries = (groupedEntries: any) => {
		Object.keys(groupedEntries).forEach((key) => {
			const entries = groupedEntries[key]
			for (let i = 0; i < entries.length; i++) {
				if (!entries[i].job_start_time) {
					if (i === 0) {
						const lookedUpStartTime = findStartTime(
							entries[i].staff.id,
							entries[i].date
						)
						entries[i].job_start_time =
							lookedUpStartTime || entries[i].time_in
					} else {
						entries[i].job_start_time = entries[i - 1].time_off
					}
				}
			}
		})

		return Object.values(groupedEntries).flat()
	}

	const groupedEntries = sortAndGroupEntries(timesheetsData)

	const updatedTimesheetData: any =
		updateStartTimeForMultipleEntries(groupedEntries)

	const staffTimesheets = updatedTimesheetData
		?.filter(
			(timesheet: { status: string }) =>
				timesheet?.status !== 'Deleted' && timesheet?.status !== 'Inactive'
		)
		?.map((timesheet: any) => {
			const start_time =
				moment(timesheet.job_start_time, 'HH:mm') ||
				moment(timesheet.time_in, 'HH:mm')
			const end_time = moment(timesheet.time_off, 'HH:mm')
			const difference = end_time.diff(start_time, 'hours', true) || 0
			return {
				difference: difference,
			}
		})
	
	const totalTimesheetHours = staffTimesheets?.reduce(
		(accumulator: number, item: { difference: number }) => {
			return accumulator + Number(item.difference)
		},
		0
	)

	const totalHoursSum: number = tasksData?.reduce(
		(accumulator: number, item: { total_hours: string }) => {
			return accumulator + Number(item.total_hours)
		},
		0
	)

	const estimatedSQMTotal = tasksData?.reduce(
		(accumulator: number, item: { quoted_square_meters: string }) => {
			return accumulator + Number(item.quoted_square_meters)
		},
		0
	)

	const actualSQMTotal = tasksData?.reduce(
		(accumulator: number, item: { actual_square_meters: string }) => {
			return accumulator + Number(item.actual_square_meters)
		},
		0
	)

	const percentageSQM = (actualSQMTotal / estimatedSQMTotal) * 100
	const percentageHours = (totalTimesheetHours / totalHoursSum) * 100
	const percentageHoursString = percentageHours === Infinity || isNaN(percentageHours) ? '0' : percentageHours.toFixed(2)
	const percentageSQMString = percentageSQM === Infinity || isNaN(percentageSQM) ? '0' : percentageSQM.toFixed(2)

	const columns = [
		{ field: 'type', header: 'Type' },
		{ field: 'estimated', header: 'Quoted' },
		{ field: 'used', header: 'Actual' },
		{ field: 'percent', header: 'Percent of Quoted' },
	]

	return (
		<Table
			columns={columns}
			data={[
				{ type: 'Hours', estimated: totalHoursSum, used: totalTimesheetHours, percent: percentageHoursString},
				{ type: 'Square Meters', estimated: estimatedSQMTotal, used: actualSQMTotal, percent: percentageSQMString},
			]}
			isLoading={visitLoading || tasksLoading || timesheetsLoading}
			title="Quoted hours vs. Actual hours"
			disableButtons
		/>
	)
}

export default QuoteAndActualHours
