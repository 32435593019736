import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useVisitTimesheetsById = (id?: string | number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchAllVisitTimesheetsById = async () => {
		if (!id) return null
		try {
			const response = await getRequest(
				AppRoutes.serverVisitTimesheetRoutes.getAllVisitTimesheetsById,
				id
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting Visit Timesheets')
			throw new Error('Something went wrong getting Visit Timesheets')
		}
	}

	const { data, isLoading, error } = useQuery(
		['visit_timesheets', id],
		fetchAllVisitTimesheetsById
	)

	return { data, isLoading, error }
}
