import { XCircleIcon } from '@heroicons/react/24/outline'
import { TextConfirmationDialog, Button } from 'common'
import { useUpdateQuoteStatus } from 'services/quotes/updateQuoteStatus'
import * as React from 'react'

interface Props {
	quoteId: number
	status: string
	decline?: boolean
}

export const DeclineQuote = ({ quoteId, status, decline=false}: Props) => {
	const { declineQuote } = useUpdateQuoteStatus()
	const [confirmationTextData, setConfirmationTextData] = React.useState('')
	return (
		<TextConfirmationDialog
			icon="danger"
			title="Decline Quote"
			body="Are you sure you want to decline this quote?"
			confirmationTextData={confirmationTextData}
			setConfirmationTextData={setConfirmationTextData}
			triggerButton={
				<button
					type="button"
					id={`${quoteId}`}
					className={
						status !== 'Approved' && status !== 'Declined'
							? 'inline-flex items-center text-sm font-medium focus:outline-none hover:text-red-400'
							: 'inline-flex items-center text-sm text-gray-200'
					}
					disabled={status === 'Approved' || status === 'Declined'}>
					<XCircleIcon
						className={
							status !== 'Approved' && status !== 'Rejected'
								? '-ml-0.5 mr-2 h-4 w-4 text-red-400'
								: '-ml-0.5 mr-2 h-4 w-4 text-red-100'
						}
						aria-hidden="true"
					/>
					Decline
				</button>
			}
			decline={decline = true}
			confirmButton={
				<Button
					size="sm"
					variant="danger"
					onClick={async () => declineQuote(quoteId,confirmationTextData)}>
					Decline
				</Button>
			}
		/>
	)
}
