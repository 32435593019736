import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useCreateWeeklyHireInvoice = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest } = useApi()
	const queryClient = useQueryClient()

	const createWeeklyHire = async (
		job_id: string | number,
		hireInvoice: unknown
	) => {
		try {
			const response = await putRequest(
				AppRoutes.serverInvoiceRoutes.createWeeklyInvoice,
				hireInvoice,
				job_id
			)
			queryClient.invalidateQueries(['invoices'])
			queryClient.invalidateQueries(['invoices-Pending'])
			queryClient.invalidateQueries(['job_weekly_hire', job_id])
			showSuccess('Weekly Hire Invoice Created successfully')
			return response
		} catch (error) {
			showError('Something went wrong creating the Weekly Hire Invoice')
			throw new Error((error as Error).message)
		}
	}

	return { createWeeklyHire }
}
