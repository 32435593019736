import { JobsServices, LeaveServices, VisitServices } from 'services'
import EventView from './EventContent'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { EventDropArg } from '@fullcalendar/core'
import { useCallback, useContext, useMemo } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'

export function JobVisitsSchedulerUtils(revertEventToggle: { (): void }) {
	const location = useLocation()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventContent = (view: any) => (
		<EventView event={view.event} location={location} />
	)
	const { data: visitsData, isLoading: visitsLoading } =
		VisitServices.useVisits()
	const filteredVisitData = useMemo(() => {
		return visitsData?.filter(
			(visit: { status: string }) => visit.status === 'Active'
		)
	}, [visitsData])
	const { data: leavesData, isLoading: leavesLoading } =
		LeaveServices.useAllApprovedLeaves()
	const { data: taskData, isLoading: taskLoading } = JobsServices.useTask()
	const { updateVisit } = VisitServices.useUpdateVisit()
	const { showInfo, showError } = useContext(NotificationsContext)
	const headerToolbar = {
		left: 'title',
		right: 'prev,today,next',
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventDrop = async (eventArgs: EventDropArg) => {
		const event = eventArgs.event
		if (event.title === 'Completed') {
			event.setStart(event.extendedProps.originalStart)
			showError('Cannot change dates for completed visits.')
			revertEventToggle()
		} else if (event.title === 'Pending Close of Visit') {
			event.setStart(event.extendedProps.originalStart)
			showError("Cannot change dates for visits you've already started.")
			revertEventToggle()
		} else {
			const visitId = Number(event.id)
			const newDate = moment(event?.start).format('DD/MM/YYYY')
			if (visitId && newDate) {
				const payload: {
					date: string
					team_leader_id?: number
				} = {
					date: newDate,
				}
				if (
					eventArgs?.newResource?.id &&
					eventArgs.newResource.id !== null &&
					eventArgs.newResource.id !== undefined
				) {
					// console.log('changing resource')
					payload.team_leader_id = Number(eventArgs?.newResource?.id)
				}
				// console.log(event, 'dropped event')
				// console.log(event?.groupId, 'groupid')
				// console.log(payload)
				await updateVisit(visitId, payload)
				showInfo('Team leader updated')
			}
		}
	}

	const calculateColors = (teamLeaderId: number) => {
		/*Chris R is 6 #0082C8
		Chris M is 7 #44A4F0
		Daniel is 9 #58C487
		Harrison P is 11 #B22222
		Luke is 20 #A17CCB
		Mikey is 23 #3FADB2
		Phillip is 26 #EF61BE
		Rua is 27 #868686
		Sam is 28 #BD835E
		Default is 47 #A9A9A9
		Skip is 53 #808080*/
		let textColor = ''
		let backgroundColor = ''
		switch (teamLeaderId) {
			case 6:
				backgroundColor = '#0082C8'
				break
			case 7:
				backgroundColor = '#44A4F0'
				break
			case 9:
				backgroundColor = '#58C487'
				break
			case 11:
				backgroundColor = '#B22222'
				break
			case 20:
				backgroundColor = '#A17CCB'
				break
			case 23:
				backgroundColor = '#3FADB2'
				break
			case 26:
				backgroundColor = '#EF61BE'
				break
			case 27:
				backgroundColor = '#868686'
				break
			case 28:
				backgroundColor = '#BD835E'
				break
			case 47:
				backgroundColor = '#A9A9A9'
				break
			case 53:
				backgroundColor = 'gray'
				break
			default:
				backgroundColor = '#D15C62'
				break
		}
		return {
			backgroundColor: backgroundColor,
		}
	}

	const formatEvents = useCallback(() => {
		if (!visitsLoading && filteredVisitData) {
			return filteredVisitData.map((visit: any) => {
				const { backgroundColor } = calculateColors(visit.teamLeaderData?.id)

				// Filter taskData down based on whether its id is in list visit.task_ids
				const tasks = taskData
					?.filter((task: { id: number }) =>
						visit.task_ids.includes(String(task.id))
					)
					.map((task: any) => {
						return `${task.zone_label} - ${task.type} - ${task.description}`
					})

				return {
					id: visit.id,
					resourceId: visit.teamLeaderData?.id,
					job_id: visit?.jobData?.job_num,
					title: visit.visit_status,
					teamLeader: visit.teamLeaderData?.staff_name || '',
					description: visit?.jobData?.descriptionOfQuote,
					client_name: visit?.jobData?.clientData?.client_name,
					tasks: tasks,
					textColor: 'black',
					backgroundColor: backgroundColor,
					type: visit?.type,
					site: visit.jobData?.site,
					start: moment(visit?.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
					branch: visit?.jobData?.branch,
				}
			})
		}
		return []
	}, [visitsLoading, filteredVisitData, taskData])

	const formatLeaves = useCallback(() => {
		if (!leavesLoading && leavesData) {
			const leaveMap = new Map()

			leavesData.forEach((leave: any) => {
				const { backgroundColor } = calculateColors(leave.staffData?.id)

				for (let i = 0; i < leave.totalDays; i++) {
					const adjustedStartDate = moment(leave?.startDate, 'DD/MM/YYYY')
						.add(i, 'days')
						.format('YYYY-MM-DD')

					const leaveKey = `${leave.staffData?.id}-${adjustedStartDate}`

					if (!leaveMap.has(leaveKey)) {
						leaveMap.set(leaveKey, {
							id: `leave-${leave.id}-${i}`,
							resourceId: leave.staffData?.id,
							job_id: 'N/A',
							title: `Leave: ${leave.type}`,
							teamLeader: leave.staffData?.staff_name || '',
							description: `Approved by ${leave.approvedBy}`,
							client_name: 'N/A',
							tasks: ['N/A'],
							textColor: 'black',
							backgroundColor: backgroundColor,
							type: 'Approved Leave',
							site: 'N/A',
							start: adjustedStartDate,
							branch: leave?.staffData?.branch,
						})
					}
				}
			})

			return Array.from(leaveMap.values())
		}

		return []
	}, [leavesLoading, leavesData])

	return {
		formatEvents,
		visitsData: filteredVisitData,
		visitsLoading,
		headerToolbar,
		eventDrop,
		eventContent,
		formatLeaves,
		leavesData,
		leavesLoading,
	}
}
