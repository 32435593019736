import { DocumentIcon } from '@heroicons/react/24/outline'
import { ConfirmationDialog, Button, Dropdown, Input, DateSelect } from 'common'
import { ContactServices, JobsServices, StaffServices } from 'services'
import { ProgressSpinner } from 'primereact/progressspinner'
import moment from 'moment'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { OptionsForDropdownFilter } from 'utilities'

const yesNoOptions = [
	{
		value: 'Yes',
		label: 'Yes',
	},
	{
		value: 'No',
		label: 'No',
	},
]

const invoiceTypeOptions = [
	{ value: 'Payment Claim', label: 'Payment Claim' },
	{ value: 'Invoice', label: 'Invoice' },
]

const invoiceManagerOptions = [
	{ value: 'Unassigned', label: 'Unassigned' },
	{ value: 'Jonny', label: 'Jonny' },
	{ value: 'Kim', label: 'Kim' },
	{ value: 'Moana', label: 'Moana' },
]

interface IProps {
	job_id: number | undefined
	setHandover: (value: string | number) => void
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handover: any
	job: any
}

interface IinitialValues {
	billing_address: string
	work_safe: string
	sssp_added: string
	hs_officer_phone: string
	hs_officer_email: string
	site_forman_email: string
	site_forman_phone: string
	gear_shortages: string
	allowed_quote: string
	engaged_engineer: string
	staff_availability: string
	booked_shrinkwrappers: string
	swms_added: string
	worksafe_uploaded: string
	worksafe_uploaded_when: string
	hs_officer: string
	site_forman: string
	invoiceType: string
	hs_officer_client: string
	hs_officer_client_number: string
	hs_officer_client_email: string
	site_forman2: string
	site_forman_phone2: string
	site_forman_email2: string
	jha_is_required: string
	swms_is_required: string
	confined_spaces_is_required: string
	hot_works_is_required: string
	invoice_manager: string
}

export const HandoverFrom = ({
	job_id,
	setHandover,
	handover,
	job,
}: IProps) => {
	const [updateLoading, setUpdateLoading] = useState(false)
	const { update } = JobsServices.useUpdateHandover()
	const { data: contactsData } = ContactServices.useContactsByClientId(
		job?.client_id
	)

	const initialValues: IinitialValues = {
		// Financials
		billing_address: handover?.billing_address || 'No',
		allowed_quote: handover?.allowed_quote || ' ',
		invoiceType: handover?.invoiceType || 'Invoice',
		hs_officer: handover?.hs_officer || 'Michael Gibson',
		hs_officer_phone: handover?.hs_officer_phone || '0274386453',
		hs_officer_email:
			handover?.hs_officer_email || 'mike@naylandscaffold.co.nz',
		site_forman:
			handover?.site_forman || job?.clientContactData?.name || ' ',
		site_forman_email:
			handover?.site_forman_email ||
			job?.clientContactData?.email ||
			' ',
		site_forman_phone:
			handover?.site_forman_phone ||
			job?.clientContactData?.phone ||
			' ',
		work_safe: handover?.work_safe || ' ',
		worksafe_uploaded: handover?.worksafe_uploaded || ' ',
		worksafe_uploaded_when: handover?.worksafe_uploaded_when
			? handover?.worksafe_uploaded_when
			: '',
		sssp_added: handover?.sssp_added || ' ',
		swms_added: handover?.swms_added || ' ',
		site_forman2: handover?.site_forman2 || ' ',
		site_forman_email2: handover?.site_forman_email2 || ' ',
		site_forman_phone2: handover?.site_forman_phone2 || ' ',
		gear_shortages: handover?.gear_shortages || ' ',
		engaged_engineer: handover?.engaged_engineer || ' ',
		staff_availability: handover?.staff_availability || ' ',
		booked_shrinkwrappers: handover?.booked_shrinkwrappers || ' ',
		hs_officer_client: handover?.hs_officer_client || ' ',
		hs_officer_client_number: handover?.hs_officer_client_number || ' ',
		hs_officer_client_email: handover?.hs_officer_client_email || ' ',
		jha_is_required: handover?.jha_is_required || 'Yes',
		swms_is_required: handover?.swms_is_required || 'No',
		confined_spaces_is_required: handover?.confined_spaces_is_required || 'No',
		hot_works_is_required: handover?.hot_works_is_required || 'No',
		invoice_manager: handover?.invoice_manager || 'Unassigned',
	}
	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			const body = {
				...initialValues,
				billing_address: values.billing_address,
				// credit_check: values.credit_check,
				// credit_check_who: values.credit_check_who,
				// credit_check_when: values.credit_check_when || ' ',
				invoiceType: values.invoiceType,
				hs_officer: values.hs_officer,
				hs_officer_phone: values.hs_officer_phone,
				hs_officer_email: values.hs_officer_email,
				site_forman: values.site_forman,
				site_forman_email: values.site_forman_email,
				site_forman_phone: values.site_forman_phone,
				jobData: handover.jobData,
				jha_is_required: values?.jha_is_required,
				swms_is_required: values?.swms_is_required,
				confined_spaces_is_required: values?.confined_spaces_is_required,
				hot_works_is_required: values?.hot_works_is_required,
				work_safe: values.work_safe,
				worksafe_uploaded_when: values.worksafe_uploaded_when,
				invoice_manager: values.invoice_manager,
			}
			try {
				setUpdateLoading(true)
				const feedbackMessage =
					handover.invoiceType == ''
						? 'Document created successfully'
						: 'Job updated successfully'
				await update(handover.id, body, feedbackMessage)
				setHandover({
					...handover,
					...body,
				})
			} finally {
				setUpdateLoading(false)
				setSubmitting(false)
				formik.resetForm()
			}
		},
	})

	useEffect(() => {
		if (formik.values.site_forman && contactsData) {
			const selectedContact = contactsData.find(
				(contact: { name: string }) => contact.name === formik.values.site_forman
			)
			if (selectedContact) {
				formik.setFieldValue('site_forman_phone', selectedContact.phone || '')
				formik.setFieldValue('site_forman_email', selectedContact.email || '')
			}
		}
	}, [formik.values.site_forman, contactsData])

	if (!job_id) {
		return <></>
	}
	return (
		<ConfirmationDialog
			size="4xl"
			icon="info"
			title="Job Contacts Document"
			body=""
			triggerButton={
				<Button
					size="sm"
					variant="inverse"
					className="mt-4"
					startIcon={<DocumentIcon className="w-4 h-4" />}>
					Job Contacts Document
				</Button>
			}
			confirmButton={
				updateLoading ? (
					<ProgressSpinner
						style={{ width: '50px', height: '50px' }}
						strokeWidth="8"
						fill="var(--surface-ground)"
						animationDuration=".5s"
					/>
				) : (
					<Button
						type="submit"
						form="handoverForm"
						variant="approve"
						size="md"
						onClick={() => {
							formik.handleSubmit()
						}}>
						{handover.invoiceType == '' ? (
							<>Create Job Contacts Document</>
						) : (
							<>Update Job Contacts Document</>
						)}
					</Button>
				)
			}>
			<div>
				{/** ****************************************
				 *
				 * FINANCIALS
				 *
				 **************************************** */}
				<div>
					<h2 className="pl-4 text-md leading-6 uppercase text-gray-700 my-4">
						FINANCIALS
					</h2>
					<div className="border-b" />
					{/* <div className="flex items-center">
						<Dropdown
							label="Billing Address Same as Client?"
							id="billing_address"
							value={formik.values.billing_address}
							error={formik.errors.billing_address}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={yesNoOptions}
						/>
					</div> */}

					<div className="flex items-center">
						<Dropdown
							label="Invoice Type"
							id="invoiceType"
							value={formik.values.invoiceType}
							error={formik.errors.invoiceType}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={invoiceTypeOptions}
						/>
						<Dropdown
							label="Invoice Manager"
							id="invoice_manager"
							value={formik.values.invoice_manager}
							error={formik.errors.invoice_manager}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={invoiceManagerOptions}
						/>
					</div>
				</div>
				{/** ****************************************
				 *
				 * HEALTH & SAFETY
				 *
				 **************************************** */}
				<div>
					<h2 className="pl-4 text-md leading-6 uppercase text-gray-700 my-4">
						Health & Safety
					</h2>
					<div className="border-b" />

					<div className="flex items-center">
						<Input
							title="H&S Officer"
							placeholder=""
							id="hs_officer"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.hs_officer}
							error={formik.errors.hs_officer}
						/>
						<Input
							title="H&S Officer Phone #"
							placeholder=""
							id="hs_officer_phone"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.hs_officer_phone}
							error={formik.errors.hs_officer_phone}
						/>
						<Input
							title="H&S Officer Email"
							placeholder=""
							id="hs_officer_email"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.hs_officer_email}
							error={formik.errors.hs_officer_email}
						/>
					</div>
					<div className="grid grid-cols-2 gap-4">
						<Dropdown
							label="Does this Job require a JHA?"
							id="jha_is_required"
							value={formik.values.jha_is_required}
							error={formik.errors.jha_is_required}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={yesNoOptions}
						/>
						<Dropdown
							label="Does this Job require a SSSP?"
							id="swms_is_required"
							value={formik.values.swms_is_required}
							error={formik.errors.swms_is_required}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={yesNoOptions}
						/>
						<Dropdown
							label="Does this Job require a confined spaces permit?"
							id="confined_spaces_is_required"
							value={formik.values.confined_spaces_is_required}
							error={formik.errors.confined_spaces_is_required}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={yesNoOptions}
						/>
						<Dropdown
							label="Does this job work around Power Lines?"
							id="hot_works_is_required"
							value={formik.values.hot_works_is_required}
							error={formik.errors.hot_works_is_required}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={yesNoOptions}
						/>
						<Dropdown
							label="Worksafe Notified?"
							id="work_safe"
							value={formik.values.work_safe}
							error={formik.errors.work_safe}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							options={[
								{
									value: 'No',
									label: 'No',
								},
								{
									value: 'Yes',
									label: 'Yes',
								},
								{
									value: 'Not Required',
									label: 'Not Required',
								},
							]}
						/>
						{formik.values.work_safe == 'Yes' && (
							<DateSelect
								title="Worksafe Notification Expiry Date"
								id="worksafe_uploaded_when"
								value={formik.values.worksafe_uploaded_when}
								onChange={(field, value) => {
									formik.setFieldValue(field, value)
								}}
							/>
						)}
					</div>
				</div>

				{/** ****************************************
				 *
				 * OPERATIONS
				 *
				 **************************************** */}
				<div>
					<h2 className="pl-4 text-md leading-6 uppercase text-gray-700 my-4">
						Operations
					</h2>
					<div className="border-b" />
					<div className="w-1/2">
						<Dropdown
							id="site_forman"
							label="Client Site Contact"
							options={OptionsForDropdownFilter(
								contactsData,
								'name',
								['name'],
								'status',
								'Active'
							)}
							value={formik.values.site_forman}
							onChange={formik.setFieldValue}
							error={formik.errors.site_forman}
						/>
					</div>
					<div className="flex items-center">
						<Input
							title="Client Site Contact Phone"
							placeholder=""
							id="site_forman_phone"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.site_forman_phone}
							error={formik.errors.site_forman_phone}
						/>
						<Input
							title="Client Site Contact Email"
							placeholder=""
							id="site_forman_email"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.site_forman_email}
							error={formik.errors.site_forman_email}
						/>
					</div>
				</div>
			</div>
		</ConfirmationDialog>
	)
}
