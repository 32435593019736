export const cleanUpSiteAddress = (address: string): string => {
  // Replace multiple commas with a single comma
  address = address.replace(/,+/g, ',')

  // Remove commas next to spaces
  address = address.replace(/\s*,\s*/g, ', ')

  // Remove commas before hyphens
  address = address.replace(/,\s*-/g, ' -')

  // Remove multiple consecutive spaces
  address = address.replace(/\s+/g, ' ')

  // Remove multiple consecutive commas followed by spaces
  address = address.replace(/(,\s*)+/g, ', ')

  // Remove leading and trailing commas and spaces
  address = address.replace(/^,|,$|,\s*$|^\s*,/g, '')

  // Trim the string to remove any leading or trailing spaces
  return address.trim()
}
