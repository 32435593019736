import { View, Text } from '@react-pdf/renderer'
import { IQuoteAdditionalLines } from 'models'
import { AddOnsFooterStyles as styles } from './styles'
import { numberFormat } from 'utilities'

interface TotalsFooterRowProps {
	total: number
}

export const TotalsFooterRow = ({ total }: TotalsFooterRowProps) => {

	return (
		<>
			<View style={styles.row}>
				<Text style={styles.description}>Total (ex. GST)</Text>
				<Text style={styles.total}>{numberFormat.format(total)}</Text>
			</View>
			<View style={styles.row}>
				<Text style={styles.description}>Total GST</Text>
				<Text style={styles.total}>{numberFormat.format(total * 0.15)}</Text>
			</View>
			<View style={styles.row}>
				<Text style={styles.description}>Total (inc. GST)</Text>
				<Text style={styles.total}>{numberFormat.format(total + (total * 0.15))}</Text>
			</View>
		</>
	)
}
