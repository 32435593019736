import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useProgressClaimUpdate = () => {
	const { showError, showSuccess } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const update = async (id: number, data: unknown) => {
		try {
			const response = await postRequest(
				AppRoutes.serverJobsRoutes.progressClaimUpdate,
				{},
				id
			)
			showSuccess('Progress claims updated successfully')
			queryClient.refetchQueries(['job_task'])
			return response.data
		} catch (error) {
			showError('Something went wrong when updating tasks')
			throw new Error('Something went wrong updating tasks')
		}
	}

	return { update }
}
