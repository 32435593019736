import { EventsServices, JobsServices, VisitServices } from 'services'
import EventView from './EventContent'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { EventDropArg } from '@fullcalendar/core'
import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'

export function EventSchedularUtils() {
	const location = useLocation()
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventContent = (view: any) => (
		<EventView event={view.event} location={location} />
	)
	const { data: eventsData, isLoading: eventsLoading } = EventsServices.useEvents()
	const { updateEvent } = EventsServices.useUpdateEvent()

	const { showInfo } = useContext(NotificationsContext)
	const headerToolbar = {
		left: 'title',
		right: 'prev,today,next',
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const eventDrop = async (eventArgs: EventDropArg) => {
		const event = eventArgs.event;

	}

	const formatEvents = () => {
		if (!eventsLoading && eventsData) {
			return eventsData.map((event: any) => {

				return {
					id: event.id,
					allDay: false,
					title: event.title,
					description: event.description,
					type: event.type,
					start: event.event_start,
					end: event.event_end,
				}
			})
		}
		return []
	}

	return {
		formatEvents,
		eventsData,
		eventsLoading,
		headerToolbar,
		eventDrop,
		eventContent,
	}
}
