import { useMemo, useState } from 'react'
import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { VehicleServices, DataTableServices, StaffServices } from 'services'
import { Container, DataTableHeader, PageHeading, Spinner } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { VehiclesForm } from 'components/Vehicles'
import { PencilIcon } from '@heroicons/react/24/solid'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'
import { parse } from 'date-fns';

export const VehiclesMainTable = () => {
	const location = useLocation()
	const { data: vehicleData, isLoading: vehicleDataLoading } =
		VehicleServices.useVehicles()
	const { data: staffData, isLoading: staffDataLoading } =
		StaffServices.useStaff()
	const [openVehicleForm, setOpenVehicleForm] = useState(false)
	console.log(vehicleData)

	const preprocessedData = useMemo(() => {
		return vehicleData?.map((vehicle: any) => {
			const staff_name =
				staffData?.find((staff) => staff.id == vehicle.assigned_to)
					?.staff_name

			return {
				...vehicle,
				assigned_to: staff_name,
				safety: vehicle.OperationalStatus === 'Operational' ? 'Yes' : 'No',
			}
		})
	}, [staffData, vehicleData])

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'Status',
				filterInitialValue: 'Active',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Active', 'Inactive'],
			},
			{
				filterName: 'OperationalStatus',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Operational', 'Issue'],
			},
			{
				filterName: 'RegoDue_date',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'WOFDate_date',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'ServiceDueDate_date',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
			},
			{
				filterName: 'safety',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Yes', 'No'],
			},
		],
		additionalGlobalFilterFields: [
			'Rego',
			'Make',
			'Model',
			'assigned_to',
			'Odometer',
			'Hubometer',
			'RUC',
			'RegoDue',
			'WOFDate',
			'ServiceDueDate',
			'ServiceDueKm',
			'lastChecked',
			'checkedBy',
			'safety'
		],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	if (staffDataLoading || vehicleDataLoading) {
		return <Spinner />
	}

	return (
		<div className="card">
			<PageHeading
				title="Vehicles"
				createBtn="Create Vehicle"
				isEditable={false}
				setOpen={setOpenVehicleForm}
			/>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={preprocessedData}
					loading={vehicleDataLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					stripedRows
					header={header}
					emptyMessage="No Vehicles found.">
					<Column
						field="Rego"
						header="Rego"
						body={(rowData: { id: string; Rego: string }) => {
							return (
								<Link
									to={AppRoutes.privateRoutes.VehiclesDetail.replace(
										':id',
										rowData.id || ''
									)}>
									{rowData.Rego}
								</Link>
							)
						}}
					/>
					<Column field="Make" header="Make" />
					<Column field="Model" header="Model" />
					<Column field="assigned_to" header="Assigned To" />
					<Column field="Odometer" header="Odometer" />
					<Column field="Hubometer" header="Hubo" />
					<Column field="RUC" header="RUC" />
					<Column
						field="RegoDue_date"
						header="Rego Due"
						className="w-28"
						body={(rowData: { RegoDue: string }) => {
							const [day, month, year] = rowData.RegoDue.split('/').map(Number)
							const serviceDueDate = parse(
								`${year}-${month}-${day}`,
								'yyyy-MM-dd',
								new Date()
							)
							const today = new Date()
							const thirtyDaysFromNow = new Date()
							thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

							let bgColorClass = 'bg-green-200'

							if (serviceDueDate < today) {
								bgColorClass = 'bg-red-200'
							} else if (serviceDueDate <= thirtyDaysFromNow) {
								bgColorClass = 'bg-orange-200'
							}

							return (
								<div className={`inline-block ${bgColorClass}`}>
									{rowData.RegoDue}
								</div>
							)
						}}
						{...FilterColumn.RegoDue_date}
					/>
					<Column
						field="WOFDate_date"
						header="COF/WOF Due"
						className="w-28"
						body={(rowData: { WOFDate: string }) => {
							const [day, month, year] = rowData.WOFDate.split('/').map(Number)
							const serviceDueDate = parse(
								`${year}-${month}-${day}`,
								'yyyy-MM-dd',
								new Date()
							)
							const today = new Date()
							const thirtyDaysFromNow = new Date()
							thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

							let bgColorClass = 'bg-green-200'

							if (serviceDueDate < today) {
								bgColorClass = 'bg-red-200'
							} else if (serviceDueDate <= thirtyDaysFromNow) {
								bgColorClass = 'bg-orange-200'
							}

							return (
								<div className={`inline-block ${bgColorClass}`}>
									{rowData.WOFDate}
								</div>
							)
						}}
						{...FilterColumn.WOFDate_date}
					/>
					<Column
						field="ServiceDueDate_date"
						header="Service Due Date"
						className="w-28"
						body={(rowData: { ServiceDueDate: string }) => {
							const [day, month, year] =
								rowData.ServiceDueDate.split('/').map(Number)
							const serviceDueDate = parse(
								`${year}-${month}-${day}`,
								'yyyy-MM-dd',
								new Date()
							)
							const today = new Date()
							const thirtyDaysFromNow = new Date()
							thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30)

							let bgColorClass = 'bg-green-200'

							if (serviceDueDate < today) {
								bgColorClass = 'bg-red-200'
							} else if (serviceDueDate <= thirtyDaysFromNow) {
								bgColorClass = 'bg-orange-200'
							}

							return (
								<div className={`inline-block ${bgColorClass}`}>
									{rowData.ServiceDueDate}
								</div>
							)
						}}
						{...FilterColumn.ServiceDueDate_date}
					/>
					<Column field="ServiceDueKm" header="Service Due KM" />
					<Column field="lastChecked" header="Last Checked" />
					<Column field="checkedBy" header="Checked By" />
					<Column field="safety" header="Safety" {...FilterColumn.safety}/>
					<Column
						field="OperationalStatus"
						header="Operational Status"
						{...FilterColumn.OperationalStatus}
					/>
					<Column field="Status" header="Status" {...FilterColumn.Status} />
					<Column
						field="id"
						header="Edit"
						exportable={false}
						body={(rowData: { id: string }) => {
							return (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.VehiclesEdit.replace(
											':id',
											rowData.id || ''
										),
									}}
									state={{ background: location, name: 'editVehicle' }}>
									<PencilIcon className="text-gray-600 h-4 w-4" />
								</Link>
							)
						}}
					/>
				</DataTable>
			</Container>
			<VehiclesForm
				open={openVehicleForm}
				setOpen={setOpenVehicleForm}
				heading="Create Vehicle"
				formType="create"
			/>
		</div>
	)
}
