import { Input, SideModal, Spinner } from 'common'
import { useFormik } from 'formik'
import { JobsServices } from 'services'
import * as Yup from 'yup'

interface IProps {
	task_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
	client_id?: number
}

interface IInitialValues {
	claimed_this_month: number
	task_value: number
	claimed_to_date: number
}

export const EditProgressClaimForm = ({
	task_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { data, isLoading } = JobsServices.useJobTaskById(task_id)
	const { updateTask } = JobsServices.useUpdateJobTask()

	const initialValues: IInitialValues = {
		claimed_this_month: data?.claimed_this_month || 0,
		task_value: data?.task_value || 0,
		claimed_to_date: data?.claimed_to_date || 0,
	}

	const validationSchema = Yup.object({
		claimed_this_month: Yup.number().test(
			'is-valid-claim',
			'Total claim will exceed the contract value',
			function (value) {
				const { claimed_to_date, task_value } = this.parent
				return (value || 0) + claimed_to_date <= task_value
			}
		),
	})

	const formik = useFormik<IInitialValues>({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			const data = {
				claimed_this_month: values.claimed_this_month,
			}

			if (task_id) await updateTask(task_id, data)

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (isLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-start px-3">
					<div className="w-1/2">
						<Input
							title="Claimed to Date"
							id="claimed_to_date"
							type="number"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.claimed_to_date.toFixed(2)}
							placeholder="0"
							disabled
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Remaining to be claimed"
							id="remaining_to_be_claimed"
							type="number"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={(
								formik.values.task_value - formik.values.claimed_to_date
							).toFixed(2)}
							placeholder="0"
							disabled
						/>
					</div>
				</div>
				<div className="flex items-start px-3">
					<div className="w-1/2">
						<Input
							title="Claimed this month"
							id="claimed_this_month"
							type="number"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.claimed_this_month}
							placeholder="0"
							error={formik.errors.claimed_this_month}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
