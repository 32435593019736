import { useState } from 'react'
import moment from 'moment'
import { DateSelect, Button, ConfirmationDialog } from 'common'
import { InvoiceServices, TimesheetServices } from 'services'
import { revertNumberFormat } from 'utilities'

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectedTimesheets: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setSelectedTimesheets: any
	disabled: boolean
}

export const SyncTimesheets = ({
	selectedTimesheets,
	setSelectedTimesheets,
	disabled,
}: IProps) => {
	const [isLoading, setIsLoading] = useState(false)
	const [completed, setCompleted] = useState(false)
	// const [date, setDate] = useState(moment().format('DD/MM/YYYY'))
	const { submitTimesheets } = TimesheetServices.useSubmitToXero()
	// const { approveInvoices } = InvoiceServices.useApproveInvoices()

	const syncTimesheetsHandler = async () => {
		if (selectedTimesheets) {
			setIsLoading(true)
			const timesheets = selectedTimesheets.map(
				(timesheet: any) => {
					return {
						timesheetID: timesheet.id,
						staffID: timesheet.staff_id,
						date: timesheet.date,
					}
				}
			)
			await submitTimesheets(timesheets)

			setSelectedTimesheets(null)
			setIsLoading(false)
			setCompleted(true)
		}
	}

	return (
		<ConfirmationDialog
			isDone={completed}
			icon="info"
			title="Submit to Xero"
			body="This action will submit the selected timesheets to Xero. Are you sure you wish to proceed?"
			triggerButton={
				<Button
					size="sm"
					disabled={disabled}
					variant="primary"
					onClick={() => {
						null
					}}>
						Submit to Xero
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					isLoading={isLoading}
					variant="approve"
					onClick={async () => {
						setIsLoading(true)
						await syncTimesheetsHandler()
						setIsLoading(false)
						setCompleted(true)
					}}>
					Confirm
				</Button>
			}>
		</ConfirmationDialog>
	)
}
