import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { AppStore } from 'redux/store'

export const useSubmitToXero = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()
	const userState = useSelector((store: AppStore) => store.user)

	const submitTimesheets = async (
		timesheets: {
			timesheetID: number | string
            staffID: string,
            date: string,
		}[],
	) => {
		try {
			const response = await postRequest(
				AppRoutes.serverTimesheetRoutes.submitTimesheetsToXero,
				{
					timesheets,
					staffEmail: userState.email,
				}
			)
            if( response.status !== 200 ) {
                // showError('Something went wrong submitting timesheets')
                throw new Error('Something went wrong submitting timesheets')
            }
			queryClient.invalidateQueries(['timesheets'])
            queryClient.invalidateQueries(['visit_timesheets'])
			showSuccess('Timesheets Submitted successfully')
			return response
		} catch (error) {
			showError('Something went wrong submitting timesheets')
			throw new Error((error as Error).message)
		}
	}

	return { submitTimesheets }
}
