import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'

export const useDeleteQuoteRateById = () => {
	const { showError } = useContext(NotificationsContext)
	const { deleteRequest } = useApi()

	const deleteQuoteRateById = async (id: number) => {
		try {
			const response = await deleteRequest(
				AppRoutes.serverQuoteRoutes.deleteQuoteRatesById,
				id
			)
			return response.data
		} catch (error) {
			showError('Something went wrong deleting quote rate')
			throw new Error('Something went wrong deleting quote rate')
		}
	}

	return { deleteQuoteRateById }
}
