import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useQuoteFilesByToken = (token: string) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteFilesByToken = async (token: string) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.getQuoteFilesByToken,
				token
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting quote files')
			throw new Error('Something went wrong getting quote files')
		}
	}

	if (!token) {
		return {
			data: undefined,
			isLoading: undefined,
			error: undefined,
			fetchQuoteFilesById: fetchQuoteFilesByToken,
		}
	}

	const { data, isLoading, error } = useQuery(['quote_files', token], () =>
		fetchQuoteFilesByToken(token)
	)

	return { data, isLoading, error }
}


export const useQuoteFilesById = (quoteId?: number) => {
	const { showError } = useContext(NotificationsContext)
	const { getRequest } = useApi()

	const fetchQuoteFilesById = async (quoteId: number) => {
		try {
			const response = await getRequest(
				AppRoutes.serverQuoteRoutes.getQuoteFilesById,
				quoteId
			)
			return response.data
		} catch (error) {
			showError('Something went wrong getting quote files')
			throw new Error('Something went wrong getting quote files')
		}
	}

	if (!quoteId) {
		return {
			data: undefined,
			isLoading: undefined,
			error: undefined,
			fetchQuoteFilesById,
		}
	}

	const { data, isLoading, error } = useQuery(['quote_files', quoteId], () =>
		fetchQuoteFilesById(quoteId)
	)

	return { data, isLoading, error }
}
