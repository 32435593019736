import { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { JobsComponents } from 'components'
import { VisitTimesheetForm } from 'components/VisitTimesheets'

export const VisitBasedTimesheetEdit = () => {
	const [openVisitTimesheetForm, setOpenTaskForm] = useState(true)
	const navigate = useNavigate()
	const location = useLocation()

	const { id } = useParams()
	const { job_id } = location.state || {}

	useEffect(() => {
		if (!openVisitTimesheetForm) {
			navigate(-1)
		}
	}, [openVisitTimesheetForm])

	return (
		<VisitTimesheetForm
			heading="Edit Visit Timesheet"
			open={openVisitTimesheetForm}
			setOpen={setOpenTaskForm}
			timesheet_id={Number(id)}
			job_id={Number(job_id)}
		/>
	)
}
