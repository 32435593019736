// Create Address form

import { Input } from 'common'
import { useFormik } from 'formik'
import { ClientServices, AddressServices } from 'services'
import { SideModal, Spinner, Dropdown } from 'common'
import { AppConfig } from 'config'
import Autocomplete from 'react-google-autocomplete'
import { SearchAddressV2 } from 'common/Form/Address'
import { FocusEvent } from 'react'

interface IProps {
	address_id?: number
	client_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface AddressRow {
	client_id: number
	address: Address
}

interface Address {
	full_address: string
	street_number: string
	route: string
	sublocality: string
	locality: string
	country: string
	postal_code: string

}

interface AddressDTO {
	client_id?: number
	street_number: string
	route: string
	sublocality: string
	locality: string
	country: string
	postal_code: string
}

export const AddressForm = ({
	address_id,
	client_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
	const { createClientAddress } = AddressServices.useCreateClientAddress()
	const { updateClientAddress } = AddressServices.useUpdateClientAddress()
	const { data, isLoading } = AddressServices.useAddressById(address_id)
	const { data: clientsData, isLoading: clientsLoading } =
		ClientServices.useClients()
	const googleApiKey = AppConfig.GoogleMapsApiKey

	const loadedAddress: Address = {
		full_address: data?.fullAddress || '',
		street_number: data?.street_number || '',
		route: data?.route || '',
		sublocality: data?.sublocality || '',
		locality: data?.locality || '',
		country: data?.country || '',
		postal_code: data?.postal_code || '',
	}

	const initialValues: AddressRow = {
		client_id: data?.client_id || Number(client_id) || null,
		address: loadedAddress
	}

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			if (formType === 'create') {
				
				const addressData: AddressDTO = {
					client_id: values.client_id,
					street_number: values.address.street_number,
					route: values.address.route,
					sublocality: values.address.sublocality,
					locality: values.address.locality,
					country: values.address.country,
					postal_code: values.address.postal_code,
				}
				await createClientAddress(addressData)
			} else {
				const addressData: AddressDTO = {
					street_number: values.address.street_number,
					route: values.address.route,
					sublocality: values.address.sublocality,
					locality: values.address.locality,
					country: values.address.country,
					postal_code: values.address.postal_code,
				}
				await updateClientAddress(addressData, Number(address_id))
			}

			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (client_id && (isLoading || clientsLoading)) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<>
					<div className="w-full px-2 py-2 h-full">
						<label className="block mb-1 text-sm font-medium text-gray-700">
							Site
						</label>
						<SearchAddressV2
							addressFieldId='address'
							addressFieldValue={formik.values.address}
							handleBlur={formik.handleBlur}
							setFieldValue={formik.setFieldValue}
						/>
					</div>
					{/* <div className="flex items-center">
						<div className="w-1/2">
							<Input
								title="Contact Name"
								id="name"
								type="text"
								handleChange={formik.handleChange}
								handleBlur={formik.handleBlur}
								value={formik.values.name}
								placeholder=""
								error={formik.errors.name}
							/>
						</div>
						<div className="w-1/2">
							<Dropdown
								label="Client"
								id="client_id"
								options={OptionsForDropdown(clientsData, 'id', 'client_name')}
								onChange={formik.setFieldValue}
								onBlur={formik.setFieldTouched}
								value={formik.values.client_id}
								error={formik.errors.client_id}
							/>
						</div>
					</div>
					<div className="flex items-center">
						<Input
							title="Contact #"
							id="phone"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.phone}
							placeholder=""
							error={formik.errors.phone}
						/>
						<Input
							title="Email"
							id="email"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.email}
							placeholder=""
							error={formik.errors.email}
						/>
					</div>
					<div className="flex items-center">
						<Dropdown
							label="Status"
							id="status"
							options={statusOptions}
							onChange={formik.setFieldValue}
							onBlur={formik.setFieldTouched}
							value={formik.values.status}
							error={formik.errors.status}
						/>
					</div> */}
				</>
			</SideModal>
		</>
	)
}
