import { AppRoutes } from 'config'
import { Container, TaskContainer } from './styledComponents'
import { Link, Location } from 'react-router-dom'

interface EventProps {
	type: string
	description: string
}

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	event: any
	location: Location
}

const EventView = ({ event, location }: IProps) => {
	const typeColors = {
		Install: '#10B981',
		Dismantle: '#0078d4',
		Notice: '#EF4444',
		Remedial: '#fbb724',
		Default: 'gray',
	}
	const eventProp: EventProps = event.extendedProps
	const { publicId: visitId } = event._def
	const styles = () => ({
		color: 'white',
		backgroundColor:
			typeColors[eventProp.type as keyof typeof typeColors] ||
			typeColors.Default,
	})

	return (
		<Container
			padding="0.5px"
			fontSize="0.8rem"
			color={styles().color}
			backgroundColor={styles().backgroundColor}>
			<TaskContainer>
				<b>Title:</b> {event.title}
			</TaskContainer>
			<TaskContainer>
				<b>Description:</b> {eventProp.description}
			</TaskContainer>
			<TaskContainer>
				<b>Type:</b> {eventProp.type}
			</TaskContainer>
		</Container>
	)
}

export default EventView
