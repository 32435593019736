import { AppRoutes } from 'config'
import { Container, TaskContainer } from './styledComponents'
import { Link, Location } from 'react-router-dom'

interface EventProps {
	type: string
	job_id: number
	description: string
	teamLeader: string
	client_name: string
	site: string
	textColor: string
	backgroundColor: string
	tasks: any
}

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	event: any
	location: Location
}

const EventView = ({ event, location }: IProps) => {
	const color = {
		Install: '#10B981',
		Dismantle: '#0078d4',
		Alteration: '#EF4444',
		Remedial: '#fbb724',
		Default: 'gray',
	}
	const eventProp: EventProps = event.extendedProps
	const { publicId: visitId } = event._def
	const styles = {
		color: 'white',
		backgroundColor:
			color[eventProp.teamLeader as keyof typeof color] ||
			color[eventProp.type as keyof typeof color] ||
			'#10B981',
	}

	return (
		<Link
			to={{
				pathname: AppRoutes.privateRoutes.visitsEdit.replace(
					':id',
					visitId.toString()
				),
			}}
			state={{ background: location, name: 'editVisit' }}>
			<Container
				padding="0.5px"
				fontSize="0.8rem"
				color={eventProp.textColor}
				backgroundColor={eventProp.backgroundColor}>
				<TaskContainer>
					<b>Type:</b> {eventProp.type}
				</TaskContainer>
				<TaskContainer>
					<b>Client:</b> {eventProp.client_name}
				</TaskContainer>
				<TaskContainer>
					<b>Description:</b> {eventProp.description}
				</TaskContainer>
				<TaskContainer>
					<b>Job #:</b> {eventProp.job_id}
				</TaskContainer>
				<TaskContainer>
					<b>Address:</b> {eventProp.site}
				</TaskContainer>
				<TaskContainer>
					<b>Team Leader:</b> {eventProp.teamLeader}
				</TaskContainer>
				<TaskContainer>
					<b>Tasks:</b> {eventProp.tasks}
				</TaskContainer>
			</Container>
		</Link>
	)
}

export default EventView
