import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useUpdateEvent = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateEvent = async (event: unknown, id: number | string) => {
		try {
			const response = await postRequest(
				AppRoutes.serverEventsRoutes.updateEvent,
				event,
				id
			)
			queryClient.refetchQueries(['events'])
			showSuccess('Event created successfully')
			return response
		} catch (error) {
			showError('Something went wrong creating event')
			throw new Error((error as Error).message)
		}
	}
	return { updateEvent }
}
