import { Dropdown, Input, SideModal, Spinner, DateSelect } from 'common'
import { useFormik } from 'formik'
import { InvoiceServices, JobsServices } from 'services'
import * as Yup from 'yup'
import { yes_no_options } from 'models'
import { OptionsForDropdownFilter } from 'utilities'
import { useEffect } from 'react'

interface IProps {
	job_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
}

interface IinitialValues {
	on_hire: string
	zone: string
	zone_label: string
	type: string
	description: string
	erect_percent: number
	date_on_hire: string | null
	completed_date: string | null
	weekly_hire_rate: number
	sqm_rate: number
	task_id: number | null
}

export const WeeklyHireInvoiceCreateForm = ({
	job_id,
	heading,
	setOpen,
	formType,
	open,
}: IProps) => {
  const { data: TaskData, isLoading: taskIsLoading } = JobsServices.useJobTask(job_id)

	const { createWeeklyHire } = InvoiceServices.useCreateWeeklyHireInvoice()

	const initialValues: IinitialValues = {
		on_hire: 'No',
		zone: '',
		zone_label: '',
		type: '',
		description: '',
		erect_percent: 0,
		date_on_hire: null,
		completed_date: null,
		weekly_hire_rate: 0,
		sqm_rate: 2.25,
		task_id: null,
	}

	const validationSchema = Yup.object({
		erect_percent: Yup.number()
			.max(100, 'Percent Erect must be smaller than or equal to 100'),
    task_id: Yup.number().required('Task is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'create' && job_id) {
				createWeeklyHire(job_id, values)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

  useEffect(() => {
		if (formik.values.task_id) {
			const task = TaskData.find(
				(task: { id: number }) =>
					Number(task.id) === Number(formik.values.task_id)
			)
			formik.setFieldValue('zone', task.zone)
			formik.setFieldValue('type', task.type)
			formik.setFieldValue('zone_label', task.zone_label)
			formik.setFieldValue('description', task.description)
		}
	}, [formik.values.task_id, TaskData, formik.setFieldValue])

	if (taskIsLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
        <div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="task_id"
							label="Task"
							options={OptionsForDropdownFilter(
								TaskData,
								'id',
								['zone_label', 'type', 'description'],
								'job_id',
								job_id!
							)}
							onChange={formik.setFieldValue}
							value={formik.values.task_id}
							error={formik.errors.task_id}
						/>
					</div>
				</div>
        <div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Section"
							id="zone_label"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Section"
							value={formik.values.zone_label}
							error={formik.errors.zone_label}
							disabled
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Type"
							id="type"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Type"
							value={formik.values.type}
							error={formik.errors.type}
              disabled
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-full">
						<Input
							id="description"
							title="Description"
							placeholder="Description"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.description}
							error={formik.errors.description}
              disabled
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="weekly_hire_rate"
							title="Weekly Hire Rate"
							placeholder=""
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.weekly_hire_rate}
							error={formik.errors.weekly_hire_rate}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="sqm_rate"
							title="SQM Rate"
							placeholder=""
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.sqm_rate}
							error={formik.errors.sqm_rate}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<Input
							id="erect_percent"
							title="Percent Erect"
							placeholder="Percent Erect"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.erect_percent}
							error={formik.errors.erect_percent}
						/>
					</div>

					<div className="w-1/2">
						<Dropdown
							id="on_hire"
							label="On Hire"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							options={yes_no_options}
							value={formik.values.on_hire}
							error={formik.errors.on_hire}
						/>
					</div>
				</div>
				<div className="flex items-center px-2">
					<div className="w-1/2">
						<DateSelect
							id="date_on_hire"
							title="Date On Hire"
							onChange={formik.setFieldValue}
							value={formik.values.date_on_hire}
							error={formik.errors.date_on_hire}
						/>
					</div>
					<div className="w-1/2">
						<DateSelect
							id="completed_date"
							title="Completed Date"
							onChange={formik.setFieldValue}
							value={formik.values.completed_date}
							error={formik.errors.completed_date}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
