import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'
import { Badge } from 'common'
import { AppStore } from 'redux/store'
import { useSelector } from 'react-redux'

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setLoggedIn: any
}

export function XeroStatus({ setLoggedIn }: IProps) {
	const [xeroStatus, setXeroStatus] = useState(false)
	const [xeroUrl, setXeroUrl] = useState('')
	const userState = useSelector((store: AppStore) => store.user)
	const [fetchedInfo, setFetchedInfo] = useState(false)
	// Active
	useEffect(() => {
		if (!userState.email) return

		fetch(`https://nayland-back.scaffm8.co.nz/invoices/xero-status?id=${userState.email}`)
			.then((response) => response.json())
			.then((data) => {
                console.log(data)
				setXeroStatus(data?.xeroStatus)
				setXeroUrl(data?.url)
				setLoggedIn(data?.xeroStatus)
				setFetchedInfo(true)
			})
	}, [userState.email])

    console.log(xeroUrl)

	if (!fetchedInfo) {
		return (
			<div className="px-6 flex items-center">
				<Badge type="Pending" text="Xero Status: Loading" />
			</div>
		)
	}

	if (!xeroStatus) {
		return (
			<div className="flex flex-1 flex-col">
				<div className="px-6 flex items-center">
					<Badge type="Issue" text="Xero Status: Login Required" />
				</div>
				<div className="px-6 flex items-center mt-4">
					<Button
						label="Login With Xero"
						onClick={() => window.location.replace(xeroUrl)}
					/>
				</div>
			</div>
		)
	}

	return (
		<div className="px-6 flex items-center">
			<Badge type="Active" text="Xero Status: Active" />
		</div>
	)
}
