import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useDeleteClientAddress = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { deleteRequest } = useApi()
	const queryClient = useQueryClient()

	const deleteClientAddress = async (client_id: number) => {
		try {
			const response = await deleteRequest(
				AppRoutes.serverAddressRoutes.getAddressById,
				client_id
			)
			queryClient.refetchQueries(['addresses'])
			showSuccess('Address deleted successfully')
			return response
		} catch (error) {
			showError('Something went wrong when deleting an address')
			throw new Error((error as Error).message)
		}
	}
	return { deleteClientAddress }
}
