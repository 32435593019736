import { useState } from 'react'
import moment from 'moment'
import { DateSelect, Button, ConfirmationDialog } from 'common'
import { JobsServices } from 'services'

interface IProps {
	job_id: number
}

export const NewMonth = ({ job_id }: IProps) => {
	const [completed, setCompleted] = useState(false)
	const [startDate, setStartDate] = useState(moment().format('DD/MM/YYYY'))
	const [finishDate, setFinishDate] = useState(moment().format('DD/MM/YYYY'))

	const { newMonth } = JobsServices.useProgressClaimNewMonth()

	const handleNewMonth = async () => {
		setCompleted(false)
		await newMonth(job_id, { startDate, finishDate })
		setCompleted(true)
	}

	return (
		<ConfirmationDialog
			isDone={completed}
			icon="info"
			title="New Month"
			body="This action will bring the completed hours and claims. Select a date range to be processed:"
			triggerButton={
				<Button
					size="sm"
					variant="primary"
					onClick={() => {
						null
					}}>
					New Month
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					variant="primary"
					onClick={async () => {
						handleNewMonth()
					}}>
					Confirm
				</Button>
			}>
			<div className="flex">
				<div className="mt-4 ml-12 mb-40">
					{/* <ReactDatePicker
						selected={
							new Date(
								`${selectedMonthYear.month}/01/${selectedMonthYear.year}`
							)
						}
						onChange={handleDateChange}
						dateFormat="MM/yyyy"
						showMonthYearPicker
						title="Select Month and Year"
						className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-10 rounded-md shadow-sm pl-2"
					/> */}
					<DateSelect
						title="Start Date"
						id="startDate"
						value={startDate}
						onChange={(e, val) => {
							setStartDate(val)
						}}
					/>
					<DateSelect
						title="Finish Date"
						id="finishDate"
						value={finishDate}
						onChange={(e, val) => {
							setFinishDate(val)
						}}
					/>
				</div>
			</div>
		</ConfirmationDialog>
	)
}
