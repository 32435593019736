import { FilterMatchMode } from 'primereact/api'
import { DataTable } from 'primereact/datatable'
import { ApproveInvoices, DeleteInvoice } from 'components/Invoices'
import {
	InvoiceServices,
	DataTableServices,
	TimesheetServices,
	VisitTimesheetsServices,
	ScaffoldRegisterServices,
} from 'services'
import { Container, DataTableHeader, PageHeading } from 'common'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { Column } from 'primereact/column'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { numberFormat } from 'utilities'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { AppRoutes } from 'config'
import { XeroStatus } from 'components/Invoices/XeroStatus'
import moment from 'moment'

export const MainInvoicesTable = () => {
	const location = useLocation()
	const jobNumFilter = location.state?.job_num || ''
	const { data, isLoading, enableCreateUpdate } =
		InvoiceServices.useInvoices('Pending')
	const { data: timesheetsData, isLoading: timesheetsLoading } =
		TimesheetServices.useTimesheets()
	const { data: visitTimesheetsData, isLoading: visitTimesheetsLoading } =
		VisitTimesheetsServices.useVisitTimesheets()
	const { data: scaffoldsData, isLoading: scaffoldsLoading } =
		ScaffoldRegisterServices.useScaffoldRegister()
	const [invoicesSelected, setInvoicesSelected] = useState([])
	const [generatedData, setGeneratedData] = useState<any>([])

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'invoiceType',
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterInitialValue: '',
				filterOptions: [],
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
			},
		],
		additionalGlobalFilterFields: [
			'zone_label',
			'type',
			'description',
			'on_hire',
			'actual_square_meters',
			'erect_percent',
			'date_on_hire',
			'completed_date',
			'days_on_hire',
			'weekly_hire_rate',
			'hourly_rate',
			'total',
			'updatedAt',
			'jobData.job_num',
			'jobData.site',
			'jobData.clientData.client_name',
		],
	})

	useEffect(() => {
		if (jobNumFilter) {
			setGlobalFilterValue(jobNumFilter)
		}
	}, [jobNumFilter, setGlobalFilterValue])

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	// <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
	// 				<Column field="invoiceType" header="Invoice Type" />
	// 				<Column field="zone_label" header="Section Label" />
	// 				<Column field="type" header="Type" />
	// 				<Column field="description" header="Description" />
	// 				<Column field="on_hire" header="On Hire" />
	// 				<Column field="erect_percent" header="% Erect" />
	// 				<Column field="date_on_hire" header="Date On Hire" />
	// 				<Column field="completed_date" header="Date Completed" />
	// 				<Column field="days_on_hire" header="Days on Hire" />
	// 				<Column
	// 					field="weekly_hire_rate"
	// 					header="Weekly Hire Rate"
	// 					body={(row: { weekly_hire_rate: number }) => {
	// 						return numberFormat.format(row.weekly_hire_rate || 0) || ''
	// 					}}
	// 				/>
	// 				<Column
	// 					field="total"
	// 					header="Total"
	// 					body={(row: { total: number }) => {
	// 						return numberFormat.format(row.total || 0) || ''
	// 					}}
	// 				/>
	// 				{/* <Column field="quote_num" header="Quote" /> */}
	// 				<Column
	// 					field="handover_url"
	// 					header="Handover Certificate"
	// 					body={(row: { handover_url: string }) => {
	// 						if (row.handover_url) {
	// 							return (
	// 								<a href={row.handover_url} target="_blank" rel="noreferrer">
	// 									Link
	// 								</a>
	// 							)
	// 						}
	// 						return <></>
	// 					}}
	// 				/>
	// 				<Column
	// 					field="updatedAt"
	// 					header="Last Time updated"
	// 					body={(row: { updatedAt: string }) => {
	// 						return new Date(row.updatedAt).toLocaleDateString('en-NZ')
	// 					}}
	// 				/>

	useEffect(() => {
		if (
			!isLoading &&
			!visitTimesheetsLoading &&
			!timesheetsLoading &&
			!scaffoldsLoading
		) {
			const filteredVisitTimesheets = [...visitTimesheetsData]?.filter(
				(visitTimesheet: any) => {
					if (
						visitTimesheet?.status !== 'Deleted' &&
						visitTimesheet?.status !== 'Inactive'
					) {
						return visitTimesheet
					}
				}
			)

			const filteredTimesheets = [...timesheetsData]?.filter(
				(timesheet: any) => {
					if (!timesheet?.status.includes('Deleted')) {
						return timesheet
					}
				}
			)

			const findStartTime = (staffId: number, date: string) => {
				const entry = filteredTimesheets.find(
					(item: { staff_id: number; date: string }) =>
						item.staff_id === staffId && item.date === date
				)
				return entry ? entry.time_on : null
			}

			const findEndTime = (staffId: number, date: string) => {
				const entry = filteredTimesheets.find(
					(item: { staff_id: number; date: string }) =>
						item.staff_id === staffId && item.date === date
				)
				return entry ? entry.time_off : null
			}

			const sortAndGroupEntries = (data: any) => {
				const groupedEntries: any = {}

				data.forEach((item: { staff: { id: any }; date: any }) => {
					const key = `${item.staff.id}_${item.date}`
					if (!groupedEntries[key]) {
						groupedEntries[key] = []
					}
					groupedEntries[key].push(item)
				})

				Object.keys(groupedEntries).forEach((key) => {
					groupedEntries[key].sort(
						(
							a: { job_start_time: moment.MomentInput },
							b: { job_start_time: moment.MomentInput }
						) => {
							const aJobStartTime = moment(a.job_start_time, 'HH:mm').valueOf()
							const bJobStartTime = moment(b.job_start_time, 'HH:mm').valueOf()
							return aJobStartTime - bJobStartTime
						}
					)
				})

				return groupedEntries
			}

			const updateTimeForMultipleEntries = (groupedEntries: any) => {
				Object.keys(groupedEntries).forEach((key) => {
					const entries = groupedEntries[key]
					for (let i = 0; i < entries.length; i++) {
						if (!entries[i].job_start_time) {
							if (i === 0) {
								const lookedUpStartTime = findStartTime(
									entries[i].staff.id,
									entries[i].date
								)
								entries[i].job_start_time =
									lookedUpStartTime || entries[i].time_in
							} else {
								entries[i].job_start_time = entries[i - 1].time_off
							}
						}

						if (i === entries.length - 1) {
							if (entries[i].time_off) {
								const lookedUpEndTime = findEndTime(
									entries[i].staff.id,
									entries[i].date
								)
								if (lookedUpEndTime) {
									entries[i].time_off = lookedUpEndTime
								}
							}
						}
					}
				})

				return Object.values(groupedEntries).flat()
			}

			const groupedEntries = sortAndGroupEntries(filteredVisitTimesheets)

			const updatedVisitTimesheetsData: any =
				updateTimeForMultipleEntries(groupedEntries)

			const rowData: any[] | undefined = data?.map((item: any) => {
				let hours = item.hours || item.total_hours || 0
				let total = item.total
				let visitType = ''
				const jobData = item.jobData
				if (jobData.is_dayworks && item.type !== 'Inspection') {
					if (!hours && item.invoiceType === 'ED Invoice') {
						const validTimesheets = updatedVisitTimesheetsData?.filter(
							(timesheet: any) =>
								timesheet?.status !== 'Deleted' &&
								timesheet?.status !== 'Inactive' &&
								(timesheet?.visit
									? timesheet?.visit?.task_ids.includes(String(item.task_id))
									: timesheet?.task_ids?.includes(String(item.task_id))) &&
								timesheet?.ed_invoice_id === item.id
						)

						hours =
							validTimesheets?.reduce((acc: any, timesheet: any) => {
								const start = moment(
									timesheet.job_start_time || timesheet.time_in,
									'HH:mm'
								)
								const end = moment(timesheet.time_off, 'HH:mm')
								return acc + (end.diff(start, 'hours', true) || 0)
							}, 0) || 0

						visitType = validTimesheets[0]?.visit
							? validTimesheets[0]?.visit?.type
							: ''
						total = hours * item.hourly_rate
					} else if (hours && item.invoiceType === 'ED Invoice') {
						const validTimesheets = updatedVisitTimesheetsData?.filter(
							(timesheet: any) =>
								timesheet?.status !== 'Deleted' &&
								timesheet?.status !== 'Inactive' &&
								(timesheet?.visit
									? timesheet?.visit?.task_ids.includes(String(item.task_id))
									: timesheet?.task_ids?.includes(String(item.task_id))) &&
								timesheet?.ed_invoice_id === item.id
						)

						if (hours < 0) {
							hours =
								validTimesheets?.reduce((acc: any, timesheet: any) => {
									const start = moment(
										timesheet.job_start_time || timesheet.time_in,
										'HH:mm'
									)
									const end = moment(timesheet.time_off, 'HH:mm')
									return acc + (end.diff(start, 'hours', true) || 0)
								}, 0) || 0
						}

						visitType = validTimesheets[0]?.visit
							? validTimesheets[0]?.visit?.type
							: ''
						total = hours * item.hourly_rate
					}
				}
				return {
					invoiceType: item.invoiceType,
					zone_label: item.zone_label,
					type: item.type,
					description: item.description,
					// jobData.is_dayworks &&
					// item.invoiceType === 'ED Invoice' &&
					// item.type !== 'Inspection'
					// 	? `${visitType} - ${item.taskData?.description}`
					// 	: item.taskData?.description,
					on_hire: item.on_hire,
					quoted_square_meters: item?.taskData?.quoted_square_meters,
					actual_square_meters: item?.taskData?.actual_square_meters,
					hours: hours,
					sqm_percent:
						item?.taskData?.actual_square_meters &&
						item?.taskData?.quoted_square_meters
							? (item?.taskData?.actual_square_meters /
									item?.taskData?.quoted_square_meters) *
							  100
							: '',
					erect_percent: item.erect_percent,
					date_on_hire: item.date_on_hire,
					completed_date: item.completed_date,
					days_on_hire: item.days_on_hire,
					invoice_id: item.invoice_id,
					weekly_hire_rate: jobData.is_dayworks
						? ''
						: numberFormat.format(item.weekly_hire_rate || 0),
					hourly_rate: numberFormat.format(item.hourly_rate || 0) || '',
					invoice_amount: numberFormat.format(item.invoice_amount || 0) || '',
					invoiced: numberFormat.format(item.invoiced || 0) || '',
					total: numberFormat.format(total || 0) || '',
					handover_url: item.handover_url,
					updatedAt: new Date(item.updatedAt).toLocaleDateString('en-NZ'),
					id: item.id,
					job_id: item.job_id,
					jobData: item.jobData,
					taskData: item.taskData,
					is_dayworks: jobData.is_dayworks,
					job_num: item.jobData.job_num,
				}
			})

			setGeneratedData(
				rowData?.filter((item) => item.jobData.status === 'Active')
			)
		}
	}, [
		data,
		isLoading,
		visitTimesheetsData,
		visitTimesheetsLoading,
		timesheetsData,
		timesheetsLoading,
		scaffoldsData,
		scaffoldsLoading,
	])

	console.log({ generatedData })

	return (
		<div className="card">
			<PageHeading title="Invoices" />
			<div className="mb-4 mx-2">
				<XeroStatus
					setLoggedIn={() => {
						null
					}}
				/>
			</div>
			<div className="px-8 flex justify-start space-x-4">
				<ApproveInvoices
					invoicesSelected={invoicesSelected}
					setInvoicesSelected={setInvoicesSelected}
				/>
				<ApproveInvoices
					invoicesSelected={invoicesSelected}
					setInvoicesSelected={setInvoicesSelected}
					endOfMonth
				/>
			</div>
			<br />
			<div className="px-8"></div>
			<Container>
				<DataTable
					ref={dataTableReference}
					value={generatedData}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowsPerPageOptions={[25, 50, 100]}
					loading={
						isLoading ||
						visitTimesheetsLoading ||
						timesheetsLoading ||
						scaffoldsLoading
					}
					dataKey="invoice_id"
					groupRowsBy="job_id"
					sortField="job_id"
					sortOrder={-1}
					rowGroupMode="subheader"
					rowGroupHeaderTemplate={headerRow}
					filters={filters}
					globalFilterFields={globalFilterFields}
					globalFilter={globalFilterValue}
					header={header}
					selection={invoicesSelected}
					stripedRows
					onSelectionChange={(e) => {
						setInvoicesSelected(e.value)
					}}
					emptyMessage="No Invoices found."
					scrollable
					scrollHeight="860px">
					<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<Column field="invoiceType" header="Invoice Type" />
					<Column field="zone_label" header="Section Label" />
					<Column field="type" header="Type" />
					<Column field="description" header="Description" />
					<Column field="on_hire" header="On Hire" />
					<Column field="quoted_square_meters" header="Quoted SQM" />
					<Column field="actual_square_meters" header="Installed SQM" />
					<Column field="hours" header="Total Hours" />
					<Column field="sqm_percent" header="% SQM" />
					<Column field="erect_percent" header="% Erect" />
					<Column field="date_on_hire" header="Date On Hire" />
					<Column field="completed_date" header="Date Completed" />
					<Column field="days_on_hire" header="Days on Hire" />
					<Column field="weekly_hire_rate" header="Weekly Hire Rate" />
					<Column field="hourly_rate" header="Hourly Rate" />
					<Column field="invoice_amount" header="Invoice Amount" />
					<Column field="invoiced" header="Previously Invoiced" />
					<Column field="total" header="Total Invoiceable" />
					{/* <Column field="quote_num" header="Quote" /> */}
					{/* <Column
						field="handover_url"
						header="Handover Certificate"
						body={(row: { handover_url: string }) => {
							if (row.handover_url) {
								return (
									<a href={row.handover_url} target="_blank" rel="noreferrer">
										Link
									</a>
								)
							}
							return <></>
						}}
					/> */}
					<Column field="updatedAt" header="Last Time updated" />
					<Column
						header="Edit"
						body={(rowData) => {
							return (
								<Link
									to={{
										pathname: AppRoutes.privateRoutes.editInvoice
											.replace(':id', rowData.id)
											.replace(
												':invoiceType',
												rowData.invoiceType === 'Weekly Hire'
													? 'weeklyHire'
													: 'edInvoice'
											),
									}}
									state={{
										background: location,
										name: 'editInvoice',
										total: rowData.total,
										hours: rowData.hours,
										isDayworks: rowData.is_dayworks,
										job_id: rowData.job_id,
									}}>
									<PencilSquareIcon className="h-4 w-4 text-gray-500" />
								</Link>
							)
						}}
					/>
					{enableCreateUpdate && (
						<Column
							header="Delete"
							body={(row) => <DeleteInvoice invoice={row} />}
						/>
					)}
				</DataTable>
			</Container>
		</div>
	)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headerRow = (data: any) => (
	<span className="text-gray-900 font-bold">{`${data?.jobData?.job_num} - ${data.jobData?.clientData?.client_name} - ${data?.jobData?.site}`}</span>
)
