import FullCalendar from '@fullcalendar/react'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import { StyledWrapper } from '../../common/Scheduler/styledComponents'
import { JobVisitsSchedulerUtils } from 'common/Scheduler'
import { PageHeading, Spinner } from 'common'
import { useEffect, useState } from 'react'
import { VisitForm } from 'components/Visits'
import { PlusIcon } from '@heroicons/react/24/solid'
import { DateSelectArg } from '@fullcalendar/core'
import { format } from 'date-fns'

interface IFormatResource {
	id: string
	title: string
}

export function Scheduler() {
	const [openVisitForm, setOpenVisitForm] = useState(false)
	const [revertEvent, setRevertEvent] = useState(false)
	const [selectedDate, setSelectedDate] = useState({
		start: '',
		team_leader: '',
	})
	const [eventsWithLeaves, setEventsWithLeaves] = useState<any[]>([])
	const {
		visitsData,
		visitsLoading,
		leavesData,
		leavesLoading,
		headerToolbar,
		eventContent,
		eventDrop,
		formatEvents,
		formatLeaves,
	} = JobVisitsSchedulerUtils(() => setRevertEvent(!revertEvent))
	const formatResources = () => {
		const staffCrewLeaders = new Map<number, IFormatResource>()
		if (visitsData) {
			visitsData.forEach((visit: any) => {
				staffCrewLeaders.set(visit?.teamLeaderData?.id, {
					id: visit?.teamLeaderData?.id,
					title: visit?.teamLeaderData?.staff_name,
				})
				// if (visit?.teamLeaderData?.type === 'Crew Leader') {
				// 	staffCrewLeaders.push({
				// 		id: visit?.teamLeaderData?.id,
				// 		title: visit?.teamLeaderData?.staff_name,
				// 	})
				// }
			})
		}
		if (leavesData) {
			leavesData.forEach((leave: any) => {
				staffCrewLeaders.set(leave?.staffData?.id, {
					id: leave?.staffData?.id,
					title: leave?.staffData?.staff_name,
				})
			})
		}

		return Array.from(staffCrewLeaders.values())
	}

	useEffect(() => {
		const allEvents = formatEvents()
		const allLeaves = formatLeaves()

		const data = [...allEvents, ...allLeaves]
		setEventsWithLeaves(data)
	}, [formatEvents, formatLeaves])

	useEffect(() => {
		if (!openVisitForm) {
			setSelectedDate({
				start: '',
				team_leader: '',
			})
		}
	}, [openVisitForm])

	const handleDateSelect = (selectInfo: DateSelectArg) => {
		setSelectedDate({
			start: selectInfo.startStr,
			team_leader: selectInfo.resource ? selectInfo.resource.id : '',
		})
		setOpenVisitForm(true)
	}

	const handleEventDragStart = (info: { event: any }) => {
		const event = info.event
		if (
			event.title === 'Completed' ||
			event.title === 'Pending Clost of Visit'
		) {
			event.setExtendedProp('originalStart', event.start)
		}
	}

	if (visitsLoading || leavesLoading) {
		return <Spinner />
	}

	return (
		<StyledWrapper>
			<div className="flex justify-end mb-6">
				<button
					type="button"
					className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
					onClick={() => setOpenVisitForm(true)}>
					<PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
					Schedule Visit
				</button>
			</div>
			<FullCalendar
				datesSet={() => window.dispatchEvent(new Event('resize'))}
				resourceAreaHeaderContent="Staff"
				resourceAreaWidth="8%"
				filterResourcesWithEvents
				headerToolbar={{
					...headerToolbar,
					center: 'Day,Week,Month',
				}}
				slotMinWidth={100}
				views={{
					Day: {
						type: 'resourceTimelineDay',
						slotLabelInterval: { hours: 1 },
						dayHeaderContent: (args) => {
							const formattedDate = format(args.date, 'dd/MM')
							return <span>{formattedDate}</span>
						},
						weekends: true,
						eventContent,
					},
					Week: {
						type: 'resourceTimelineWeek',
						slotLabelInterval: { days: 1 },
						weekends: true,
						eventContent,
						dayHeaderContent: (args) => {
							const formattedDate = format(args.date, 'dd/MM')
							return <span>{formattedDate}</span>
						},
					},
					Month: {
						type: 'resourceTimelineMonth',
						slotLabelInterval: { days: 1 },
						weekends: true,
						eventContent,
					},
				}}
				eventContent={eventContent}
				plugins={[
					dayGridPlugin,
					timeGridPlugin,
					interactionPlugin,
					resourceTimelinePlugin,
				]}
				initialView="Week"
				height="auto"
				editable
				selectable
				select={handleDateSelect}
				dayMaxEvents
				firstDay={1}
				resources={formatResources()}
				events={eventsWithLeaves}
				eventDrop={eventDrop}
				schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
				eventDragStart={handleEventDragStart}
			/>
			<VisitForm
				heading="Schedule Visit"
				setOpen={setOpenVisitForm}
				formType="create"
				open={openVisitForm}
				startDate={selectedDate.start}
				teamLeader={selectedDate.team_leader}
			/>
		</StyledWrapper>
	)
}
