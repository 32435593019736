import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'
import { QuoteAcceptData } from 'models/quote_accept.model'

export const useUpdateAcceptStatusByToken = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const updateAcceptStatusByToken = async (acceptState: QuoteAcceptData, token: string) => {
		try {
			const response = await postRequest(
				AppRoutes.serverQuoteRoutes.updatedAcceptByToken,
				acceptState,
				token
			)
			showSuccess('Quote updated successfully')
			queryClient.refetchQueries(['quotes'])
			queryClient.refetchQueries(['quote', token])
			return response
		} catch (error) {
			showError('Something went wrong updating Quote')
			throw new Error('Something went wrong updating Quote')
		}
	}

	return {
		updateAcceptStatusByToken,
	}
}
