import { useEffect, useRef, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import {
	IQuoteLine,
	IQuoteZones,
	IRates,
	IQuoteLinesHandlers,
} from 'models/simple_quotes.model'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'primereact/button'
import { ConfirmationDialog } from 'common'
import { quoteLinesFn } from 'services/simple_quotes'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'

interface QuoteLineProps {
	zones: IQuoteZones[]
	rates: IRates[]
	data: IQuoteLine
	totalLines: number
	quoteLinesHandlers: IQuoteLinesHandlers
	index: number
	isNewQuote: boolean
}

export const SimpleQuoteLine = ({
	zones,
	rates,
	data,
	index,
	quoteLinesHandlers,
	totalLines,
	isNewQuote,
}: QuoteLineProps) => {
	const [pulseUp, setPulseUp] = useState(false)
	const [pulseDown, setPulseDown] = useState(false)
	const [dirtyFields, setDirtyFields] = useState({
		erect_and_dismantle: false,
		weekly_hire_fee: false,
	})

	const handleProductLineChange = (
		index: number,
		field: keyof IQuoteLine,
		value: string | number | boolean
	) => {
		quoteLinesHandlers.updateQuoteLine(index, field, value)
	}

	const moveLine = async (direction: 'up' | 'down') => {
		const newIndex = direction === 'up' ? index - 1 : index + 1
		quoteLinesHandlers.updateQuoteLine(index, 'moveIndex', newIndex)
		if (direction === 'up') {
			setPulseUp(true)
			setTimeout(() => {
				setPulseUp(false)
			}, 1000)
		} else {
			setPulseDown(true)
			setTimeout(() => {
				setPulseDown(false)
			}, 1000)
		}
	}

	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const autoExpand = () => {
		const textarea = textareaRef.current
		if (textarea) {
			textarea.style.height = 'auto'
			textarea.style.height = `${textarea.scrollHeight}px`
		}
	}

	useEffect(() => {
		autoExpand()
	}, [data.description])

	return (
		<tr>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="zone_id"
					options={OptionsForDropdown(zones, 'zone_id', 'zone_label')}
					id={`zone_id-${index}`}
					value={data.zone_id}
					onChange={(e) =>
						handleProductLineChange(index, 'zone_id', e.target.value)
					}
					className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="Rate"
					options={OptionsForDropdown(rates, 'service', 'service')}
					id={`type-${index}`}
					value={data.type}
					onChange={(e) =>
						handleProductLineChange(index, 'type', e.target.value)
					}
					className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			<td className="px-1 py-1 h-8 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
				<textarea
					ref={textareaRef}
					id={`description-${index}`}
					className="h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.description}
					onChange={(e) =>
						handleProductLineChange(index, 'description', e.target.value)
					}
				/>
			</td>

			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`quantity-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.square_meters}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'square_meters',
							Number(e.target.value)
						)
					}
				/>
			</td>

			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`sqm-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.quantity}
					onChange={(e) =>
						handleProductLineChange(index, 'quantity', Number(e.target.value))
					}
				/>
			</td>

			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`erect_and_dismantle-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					style={{
						background:
							data.erect_and_dismantle_is_dirty ||
							dirtyFields.erect_and_dismantle
								? '#fff2e2'
								: '',
					}}
					value={Number(data.erect_and_dismantle)}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'erect_and_dismantle',
							parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0
						)
						setDirtyFields({ ...dirtyFields, erect_and_dismantle: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`weekly_hire_fee-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					style={{
						background:
							data.weekly_hire_fee_is_dirty || dirtyFields.weekly_hire_fee
								? '#fff2e2'
								: '',
					}}
					value={data.weekly_hire_fee}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'weekly_hire_fee',
							Number(e.target.value)
						)
						setDirtyFields({ ...dirtyFields, weekly_hire_fee: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`total-${index}`}
					type="text"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-500 focus:outline-none"
					value={numberFormat.format(Number(data.total))}
					defaultValue={numberFormat.format(Number(data.total))}
					disabled
				/>
			</td>
			<td className="px-1 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
				<div className="flex flex-row space-x-4 items-start">
					<ConfirmationDialog
						icon="danger"
						title="Delete Line Item"
						body="Are you sure you want to delete this item? This action is unrecoverable!"
						triggerButton={
							<button type="button">
								<XCircleIcon
									className="flex-shrink-0 h-6 w-6 text-red-500"
									aria-hidden="true"
								/>
							</button>
						}
						confirmButton={
							<Button
								className="bg-red-600 text-white"
								onClick={async () => quoteLinesHandlers.removeQuoteLine(index)}>
								Delete Line
							</Button>
						}
					/>
					{isNewQuote && (
						<>
							<div className={`${pulseUp ? ' animate-ping' : ''}`}>
								<button
									disabled={index === 0}
									className=" text-gray-500 border-gray-700 border border-3 rounded-md hover:bg-gray-300 disabled:hover:text-gray-200 disabled:hover:bg-white disabled:text-gray-200 disabled:border-gray-200"
									type="button"
									onClick={() => {
										moveLine('up')
									}}>
									<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
								</button>
							</div>
							<div className={`${pulseDown ? ' animate-ping' : ''}`}>
								<button
									disabled={index === totalLines - 1}
									className=" text-gray-500 border-gray-700 border border-3 rounded-md hover:bg-gray-300 disabled:hover:text-gray-200 disabled:hover:bg-white disabled:text-gray-200 disabled:border-gray-200"
									type="button"
									onClick={() => {
										moveLine('down')
									}}>
									<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
								</button>
							</div>
						</>
					)}
				</div>
			</td>
		</tr>
	)
}
