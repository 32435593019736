import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'
import {
	IQuoteLine,
	IQuoteAdditionalLines,
	IQuoteZones,
	IRates,
	QuoteDataToUpdate,
} from 'models'

export const useRedactQuote = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { postRequest } = useApi()
	const queryClient = useQueryClient()

	const redactQuote = async (id: number) => {
		try {
			const response = await postRequest(
				AppRoutes.serverQuoteRoutes.updateQuote,
				{ quote_redacted: true },
				id
			)
			showSuccess('Quote redacted successfully')
			queryClient.refetchQueries(['quotes'])
			queryClient.refetchQueries(['quote', id])
			return response
		} catch (error) {
			showError('Something went wrong updating Quote')
			throw new Error('Something went wrong updating Quote')
		}
	}

	return {
		redactQuote,
	}
}
