import { Spinner, Table } from 'common'
import { PageJobHeading } from 'common/PageJobHeader'
import { useContext, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { JobsServices } from 'services'
import { numberFormat } from 'utilities'
import { NewMonth } from './NewMonth'
import { Update } from './Update'
import { AppRoutes } from 'config'
import { PencilIcon } from '@heroicons/react/24/solid'
import { isEmpty } from 'lodash'
import { NotificationsContext } from 'context/notifications/toastContext'
import { Button } from 'primereact/button'

interface ProgressClaimTableProps {
	job_id?: number
}

export const ProgressClaimTable = ({ job_id }: ProgressClaimTableProps) => {
	const location = useLocation()
	const { showError } = useContext(NotificationsContext)
	const [openCreate, setOpenCreate] = useState(false)
	const { data, isLoading, enableCreateUpdate } =
		JobsServices.useJobTaskWithTimesheets(job_id)

	const preprocessedData = useMemo(() => {
		return data?.map((task: any) => {
			const quoted_hours = task.task_value ? task.task_value / 70.5 : 0
			const cost_actual = task.actual_hours * 70.5
			return {
				...task,
				quoted_hours: quoted_hours.toFixed(2),
				percent_complete_hours: task.task_value
					? ((task.actual_hours / quoted_hours) * 100).toFixed(2)
					: (0).toFixed(2),
				cost_actual: cost_actual,
				total_claimed: task.claimed_to_date + task.claimed_this_month,
				weekly_hire_total_claimed:
					task.weekly_hire_claimed_to_date +
					task.weekly_hire_claimed_this_month,
			}
		})
	}, [data])

	if (isLoading) {
		return <Spinner />
	}

	console.log({ preprocessedData })

	const columns = [
		{ field: 'zone_label', header: 'Section' },
		{ field: 'quoted_hours', header: 'Quoted Hours' },
		{ field: 'actual_hours', header: 'Actual Hours' },
		{ field: 'percent_complete_hours', header: '% complete' },
		{
			field: 'task_value',
			header: 'Contract Value',
			body: (row: { task_value: number }) => {
				return numberFormat.format(row.task_value || 0)
			},
		},
		{
			field: 'cost_actual',
			header: 'Cost actual',
			body: (row: { cost_actual: number }) => {
				return numberFormat.format(row.cost_actual || 0)
			},
		},
		{
			field: 'claimed_to_date',
			header: 'Claimed To Date',
			body: (row: { claimed_to_date: number }) => {
				return numberFormat.format(row.claimed_to_date || 0)
			},
		},
		{
			field: 'percentage_completed_this_month',
			header: 'Month %',
		},
		{ field: 'completed_this_month', header: 'Month Hours' },
		{
			field: 'claimed_this_month',
			header: 'Claimed This Month',
			body: (row: { claimed_this_month: number }) => {
				return numberFormat.format(row.claimed_this_month || 0)
			},
		},
		{
			field: 'total_claimed',
			header: 'Total Claimed',
			body: (row: { total_claimed: number }) => {
				return numberFormat.format(row.total_claimed || 0)
			},
		},
		{
			field: 'Edit',
			header: 'Edit',
			body: (row: { id: number }) =>
				enableCreateUpdate ? (
					<Link
						to={{
							pathname: AppRoutes.privateRoutes.progressClaimEdit.replace(
								':id',
								row.id.toString()
							),
						}}
						state={{ background: location, name: 'editProgressClaim' }}>
						<PencilIcon className="h-4 w-4 text-gray-500" />
					</Link>
				) : (
					<></>
				),
		},
	]

	const weeklyHireColumns = [
		{ field: 'zone_label', header: 'Section' },
		{ field: 'on_hire', header: 'On Hire' },
		{
			field: 'date_on_hire',
			header: 'Date On Hire',
		},
		{
			field: 'completed_date',
			header: 'Completed Date',
		},
		{
			field: 'days_on_hire',
			header: 'Days On Hire',
		},
		{
			field: 'weekly_hire_rate',
			header: 'Weekly Hire Rate',
			body: (row: { weekly_hire_rate: number }) => {
				return numberFormat.format(row.weekly_hire_rate || 0)
			},
		},
		{
			field: 'total',
			header: 'Weekly Hire Total',
			body: (row: { total: number }) => {
				return numberFormat.format(row.total || 0)
			},
		},
		{
			field: 'weekly_hire_claimed_to_date',
			header: 'Claimed To Date',
			body: (row: { weekly_hire_claimed_to_date: number }) => {
				return numberFormat.format(row.weekly_hire_claimed_to_date || 0)
			},
		},
		{
			field: 'weekly_hire_claimed_this_month',
			header: 'Claimed This Month',
			body: (row: { weekly_hire_claimed_this_month: number }) => {
				return numberFormat.format(row.weekly_hire_claimed_this_month || 0)
			},
		},
		{
			field: 'weekly_hire_total_claimed',
			header: 'Total Claimed',
			body: (row: { weekly_hire_total_claimed: number }) => {
				return numberFormat.format(row.weekly_hire_total_claimed || 0)
			},
		},
		// {
		// 	field: 'Edit',
		// 	header: 'Edit',
		// 	body: (row: { id: number }) =>
		// 		enableCreateUpdate ? (
		// 			<Link
		// 				to={{
		// 					pathname: AppRoutes.privateRoutes.progressClaimEdit.replace(
		// 						':id',
		// 						row.id.toString()
		// 					),
		// 				}}
		// 				state={{ background: location, name: 'editProgressClaim' }}>
		// 				<PencilIcon className="h-4 w-4 text-gray-500" />
		// 			</Link>
		// 		) : (
		// 			<></>
		// 		),
		// },
	]

	const headerMapping = {
		zone_label: 'Section',
		task_value: 'Contract Value',
		claimed_to_date: 'Claimed To Date',
		claimed_this_month: 'Claimed This Month',
		total_claimed: 'Total Claimed',
	}

	const convertToCSV = (
		data: any[],
		headerMapping: Record<string, string>
	): string => {
		const headers = [
			'zone_label',
			'task_value',
			'claimed_to_date',
			'claimed_this_month',
			'total_claimed',
		]
		const renamedHeaders = headers.map(
			(header) => headerMapping[header] || header
		)

		const rows = data.map((row) => {
			return headers
				.map((header) => {
					const value = header
						.split('.')
						.reduce((acc: any, key: string) => (acc ? acc[key] : null), row)

					if (header === 'zone_label') {
						return value ? `"${value}"` : ''
					}

					if (typeof value === 'number' && !isNaN(value)) {
						return `"${numberFormat.format(value)}"`
					} else {
						return '$0.00'
					}
				})
				.join(',')
		})

		return [renamedHeaders.join(','), ...rows].join('\n')
	}

	const downloadCSV = (csv: string, filename: string) => {
		const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
		const link = document.createElement('a')
		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob)
			link.setAttribute('href', url)
			link.setAttribute('download', filename)
			link.style.visibility = 'hidden'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}

	const exportCSV = () => {
		if (preprocessedData) {
			if (isEmpty(preprocessedData)) {
				showError('No data to export')
			} else {
				const jobNum = `${Number(preprocessedData[0].job_id) + 1000}`
				const clientName = preprocessedData[0].quoteData.clientData.client_name
				const csv = convertToCSV(preprocessedData, headerMapping)
				downloadCSV(csv, `${jobNum} - ${clientName} - Progress Claim.csv`)
			}
		} else {
			showError('No data to export')
		}
	}

	return (
		<>
			<PageJobHeading title="Progress Claim Labour" />
			<div className="px-8 flex justify-start space-x-4">
				<div className="flex flex-row w-full justify-between">
					<Button
						size="small"
						type="button"
						icon="pi pi-file"
						label="Export"
						outlined
						onClick={exportCSV}
					/>
					<div className="flex flex-row w-[200px] justify-between">
						<Update job_id={job_id!} />
						<NewMonth job_id={job_id!} />
					</div>
				</div>
			</div>
			<Table
				columns={columns}
				data={preprocessedData}
				isLoading={isLoading ?? false}
				title=""
				disableButtons
			/>
			<PageJobHeading title="Progress Claim Weekly Hires" />
			<Table
				columns={weeklyHireColumns}
				data={preprocessedData}
				isLoading={isLoading ?? false}
				title=""
				disableButtons
			/>
		</>
	)
}
