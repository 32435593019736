import { AppFilesServices } from 'services'
import { Table } from 'common'
import moment from 'moment'

export const MainFilesTable = () => {
	const { data, isLoading } = AppFilesServices.useAppFiles()

	const cols = [
		{
			header: 'Date Added',
			field: 'created_at',
			body: (row: { created_at: Date }) => {
				return row.created_at ? moment(row.created_at).format('DD/MM/YYYY') : ''
			},
		},
		{
			header: 'Job Number',
			field: 'job.job_num',
			body: (row: { job: { job_num: string } }) => {
				return row.job?.job_num ? row.job?.job_num : 'N/A'
			},
		},
		{
			header: 'Job Site',
			field: 'job.site',
			body: (row: { job: { site: string } }) => {
				return row.job?.site ? row.job?.site : 'N/A'
			},
		},
		{
			header: 'File Name',
			field: 'file_name',
		},
		{
			header: 'File Type',
			field: 'file_type',
		},
		{
			header: 'File',
			field: 'file',
			body: (rowData: { link: string }) => <a className='text-blue-400' href={rowData.link}>Link</a>,
		},
		{
			header: 'Uploaded By',
			field: 'uploaded_by',
		},
	]

	return (
		<>
			<Table
				columns={cols}
				data={data}
				isLoading={isLoading}
				title="App Files"
			/>
		</>
	)
}
