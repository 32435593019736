import { IQuoteZones, rateDescription } from 'models'
import { IQuoteAdditionalLines, IRates } from 'models/simple_quotes.model'

const initLine: IQuoteAdditionalLines = {
	id: null,
	type: '',
	description: '',
	duration_quantity: 0,
	fixed_charge: 0,
	total_cost: 0,
	fixed_charge_is_dirty: false,
	zone_id: null,
	zone_label: '',
}

const addNewQuoteLine = (
	lines: IQuoteAdditionalLines[]
): IQuoteAdditionalLines[] => {
	lines.push(initLine)
	return lines
}

const removeQuoteLine = (
	index: number,
	quote_lines: IQuoteAdditionalLines[]
): IQuoteAdditionalLines[] => {
	const newQuoteLines = [...quote_lines]
	newQuoteLines.splice(index, 1)
	return newQuoteLines
}

const updateQuoteLine = (
	index: number,
	quote_lines: IQuoteAdditionalLines[],
	field: string,
	value: string | number
): IQuoteAdditionalLines[] => {
	const newQuoteLines = quote_lines.map((line, i) => {
		if (i === index) {
			const newLine = {
				...line,
				[field]: value,
			}
			if (field === 'type') {
				const rate = rateDescription.find(
					({ rate }) =>
						rate.trim().toLowerCase() === newLine.type.trim().toLowerCase()
				)
				if (rate) {
					newLine.description = rate.description
				} else {
					newLine.description = newLine.type
				}
			}
			return newLine
		}
		return line
	})
	return newQuoteLines
}

const calculateLines = (
	index: number,
	lines: IQuoteAdditionalLines[],
	rates: IRates[],
	field: string
): IQuoteAdditionalLines[] => {
	const newLines = lines.map((line, i) => {
		const rate = rates.find((rate) => rate.service === line.type)?.fee
		if (!rate) return line
		if (i === index) {
			if (
				!line.fixed_charge_is_dirty &&
				(field === 'duration_quantity' || field === 'type')
			) {
				const newLine: IQuoteAdditionalLines = {
					...line,
					fixed_charge: Number(rate),
					total_cost: Number(rate) * Number(line.duration_quantity),
				}
				return newLine
			} else {
				const newLine: IQuoteAdditionalLines = {
					...line,
					fixed_charge: Number(line.fixed_charge),
					fixed_charge_is_dirty:
						field === 'fixed_charge' ? true : line.fixed_charge_is_dirty,
					total_cost: Number(line.fixed_charge),
				}
				return newLine
			}
		}
		return line
	})
	return newLines
}

const checkZones = (
	index: number,
	zones: IQuoteZones[],
	lines: IQuoteAdditionalLines[],
	value: number | string
): IQuoteAdditionalLines[] => {
	const newQuoteLines = lines.map((line, idx) => {
		if (idx === index) {
			const zone = zones.find((zone) => zone.zone_id === line.zone_id)
			if (value === zones.length + 1) {
				line.zone_label = 'Additional'
				line.zone_id = value
			} else if (!zone) {
				line.zone_id = null
				line.zone_label = ''
			} else {
				line.zone_label = zone.zone_label
				line.zone_id = Number(zone.zone_id)
			}
		}
		return line
	})

	return newQuoteLines
}

export const quoteAdditionalLinesFn = {
	addNewQuoteLine,
	removeQuoteLine,
	updateQuoteLine,
	calculateLines,
	checkZones,
}
