import { AddressServices } from 'services'
import { Button, ConfirmationDialog, Spinner, Table } from 'common'
import { PencilIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { AddressForm } from 'components/Addresses'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { AppRoutes } from 'config'

interface AddressTableProps {
	client_id?: number
}

export const AddressTable = ({ client_id }: AddressTableProps) => {
	const location = useLocation()
	const [openCreate, setOpenCreate] = useState(false)
	const { data: address, isLoading } =
		AddressServices.useAddressesByClientId(client_id)
	const { deleteClientAddress } = AddressServices.useDeleteClientAddress()

	if (isLoading) {
		return <Spinner />
	}

	const columns = [
		{
			field: 'street_number',
			header: 'Street Number',
		},
		{
			field: 'route',
			header: 'Street',
		},
		{
			field: 'sublocality',
			header: 'Suburb',
		},
		{
			field: 'locality',
			header: 'City',
		},
		{
			field: 'country',
			header: 'Country',
		},
		{
			field: 'postal_code',
			header: 'Postal Code',
		},
		{
			field: 'id',
			header: 'Operations',
			body: (rowData: { id: string }) => (
				<div className="flex-row space-y-2 mt-2 mb-2 items-start">
					<Link
						to={{
							pathname: AppRoutes.privateRoutes.ClientAddressEdit.replace(
								':id',
								rowData.id
							),
						}}
						state={{ background: location, name: 'editAddress' }}>
						<div className="flex items-center">
							<PencilIcon className="text-gray-600 h-4 w-4" />{' '}
							<span className="px-2 items-center text-sm font-medium">
								Edit
							</span>
						</div>
					</Link>
					<ConfirmationDialog
						icon="danger"
						title="Delete address"
						body="Are you sure you want to delete this address?"
						triggerButton={
							<button
								type="button"
								id={`${rowData.id}`}
								className={
									'inline-flex items-center text-red-900 text-sm font-medium focus:outline-none hover:text-red-400'
								}>
								<XMarkIcon
									className={'-ml-0.5 mr-2 h-4 w-4 text-red-600'}
									aria-hidden="true"
								/>
								Delete
							</button>
						}
						confirmButton={
							<Button
								size="sm"
								variant="danger"
								onClick={async () => deleteClientAddress(Number(rowData.id))}>
								Delete
							</Button>
						}
					/>
				</div>
			),
		},
	]

	return (
		<>
			<Table
				title="Addresses"
				columns={columns}
				data={address}
				isLoading={isLoading}
				ActionName="Add New Address"
				setOpen={setOpenCreate}
				disableButtons
			/>
			<AddressForm
				client_id={client_id}
				formType="create"
				heading="Create Address"
				open={openCreate}
				setOpen={setOpenCreate}
			/>
		</>
	)
}
