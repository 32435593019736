import { useEffect, useRef, useState } from 'react'
import { XCircleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import {
	IQuoteAdditionalLines,
	IRates,
	IQuoteAdditionalLinesHandlers,
} from 'models/simple_quotes.model'
import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import { IQuoteZones } from 'models'

interface IAdditionalLineProps {
	zones: IQuoteZones[]
	rates: IRates[]
	line: IQuoteAdditionalLines
	additionalLineHandlers: IQuoteAdditionalLinesHandlers
	index: number
}

export const SimpleQuoteAdditionalLine = ({
	zones,
	rates,
	line,
	additionalLineHandlers,
	index,
}: IAdditionalLineProps) => {
	const [dirtyFields, setDirtyFields] = useState({
		fixed_charge: false,
	})
	const handleProductLineChange = (
		index: number,
		field: keyof IQuoteAdditionalLines,
		value: string | number
	) => {
		additionalLineHandlers.updateQuoteAdditionalLine(index, field, value)
	}

	const textareaRef = useRef<HTMLTextAreaElement>(null)
	const autoExpand = () => {
		const textarea = textareaRef.current
		if (textarea) {
			textarea.style.height = 'auto'
			textarea.style.height = `${textarea.scrollHeight}px`
		}
	}

	useEffect(() => {
		autoExpand()
	}, [line.description])

	return (
		<tr>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="section_id"
					options={OptionsForDropdown(
						[
							...zones,
							{
								id: null,
								zone_id: zones.length + 1,
								zone_label: 'Additional',
							},
						],
						'zone_id',
						'zone_label'
					)}
					id={`zone_id-${index}`}
					value={line.zone_id}
					onChange={(e) =>
						handleProductLineChange(index, 'zone_id', e.target.value)
					}
					className="w-full min-w-[250px] h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-52">
				<Dropdown
					//hasLabel={false}
					options={OptionsForDropdown(rates, 'service', 'service')}
					id={`type${index}`}
					value={line.type}
					onChange={(e) => {
						handleProductLineChange(index, 'type', e.target.value)
					}}
					className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
				<textarea
					ref={textareaRef}
					id={`description${index}`}
					className="h-8 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					name="description"
					value={line.description}
					onChange={(e) =>
						handleProductLineChange(index, 'description', e.target.value)
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-40">
				<input
					id={`duration_quantity${index}`}
					type="number"
					className=" pl-2 h-8 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					name="duration_quantity"
					value={line.duration_quantity}
					onChange={(e) =>
						handleProductLineChange(
							index,
							'duration_quantity',
							Number(e.target.value)
						)
					}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-40">
				<input
					id={`fixed_charge${index}`}
					type="number"
					className={clsx(
						line.type
							? 'border border-gray-100'
							: 'border border-gray-300 text-center',
						'h-8 rounded-md w-full text-gray-900 focus:outline-none pl-2 text-center'
					)}
					style={{
						background: line.fixed_charge_is_dirty ? '#fff2e2' : '',
					}}
					name="fixed_charge"
					value={line.fixed_charge}
					onChange={(e) => {
						handleProductLineChange(index, 'fixed_charge', e.target.value)
						setDirtyFields({ ...dirtyFields, fixed_charge: true })
					}}
					disabled={!line.type}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-40">
				<input
					id={`totalCost${index}`}
					type="text"
					className="pl-2 h-8 rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					name="totalCost"
					disabled
					value={numberFormat.format(Number(line.total_cost))}
				/>
			</td>
			<td className="px-1 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap">
				<button
					type="button"
					onClick={() =>
						additionalLineHandlers.removeQuoteAdditionalLine(index)
					}>
					<XCircleIcon
						className="flex-shrink-0 h-4 w-4 text-red-500"
						aria-hidden="true"
					/>
				</button>
			</td>
		</tr>
	)
}
