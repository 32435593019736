import { Badge, Table } from 'common'
import { AppRoutes } from 'config'
import { Link } from 'react-router-dom'
import { ScaffoldRegisterServices } from 'services'
import { dateFormat } from 'utilities'

interface ScaffoldRegisterTableProps {
	job_id?: number
}

export const ScaffoldRegisterTable = ({
	job_id,
}: ScaffoldRegisterTableProps) => {
	const { data, isLoading } = ScaffoldRegisterServices.useGetTagByJobId(job_id)
	const columns = [
		{
			field: 'tag_no',
			header: 'Tag #',
			body: (row: { id: string; tag_no: string }) => (
				<Link
					to={AppRoutes.privateRoutes.scaffoldRegisterDetail.replace(
						':id',
						row.id || ''
					)}>
					{row.tag_no}
				</Link>
			),
		},
		{ field: 'description', header: 'Description' },
		{
			field: '',
			header: 'Task',
			body: (rowData: { taskData: { description: string } }) => {
				return rowData.taskData?.description ? rowData.taskData.description : ''
			},
		},
		{
			field: '',
			header: 'Last Inspection',
			body: (rowData: { last_inspection_date: Date }) => {
				return rowData.last_inspection_date
					? dateFormat.format(rowData.last_inspection_date)
					: ''
			},
		},
		{
			field: '',
			header: 'Inspection Due',
			body: (rowData: { inspection_due_date: Date }) => {
				return rowData.inspection_due_date
					? dateFormat.format(rowData.inspection_due_date)
					: ''
			},
		},
		{
			field: '',
			header: 'Status',
			body: (rowData: { status: string }) => {
				return <Badge text={rowData.status} type={rowData.status} />
			},
		},
	]
	console.log(data)
	return (
		<>
			<Table
				columns={columns}
				data={data}
				isLoading={isLoading ?? false}
				title="Scaffold Register"
				disableButtons
			/>
		</>
	)
}
