import moment from 'moment'
import DatePicker from 'react-datepicker'
import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

import 'react-datepicker/dist/react-datepicker.css'

interface Props {
	title: string
	id: string
	value: string | null
	onChange: (id: string, val: string) => void
	error?: string
	disabled?: boolean
}

export function DateSelect({
	title,
	id,
	value,
	onChange,
	error,
	disabled = false,
}: Props) {
	return (
		<div className="px-2 py-2 w-full">
			<div>
			<label
				htmlFor={id}
				id={`date${id}`}
				className="block text-sm font-medium text-gray-700 mb-1">
				{title}
			</label>
			</div>
			<div>
			<DatePicker
				id={id}
				autoComplete="off"
				dateFormat="dd/MM/yyyy"
				selected={value ? moment(value, 'DD/MM/YYYY').toDate() : null}
				onChange={(val: Date) => {
					if (val) {
						const formattedValue: string = moment(val)
							.format('DD/MM/YYYY')
							.toString()
						onChange(id, formattedValue)
					} else {
						onChange(id, '')
					}
				}}
				className="border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm block w-full border h-10 rounded-md shadow-sm pl-2"
				disabled={disabled}
			/>
			</div>
			<p
				className="mt-1 text-sm text-red-600 flex items-center"
				id="date-error">
				{error && (
					<>
						<ExclamationCircleIcon
							className="w-5 h-5 text-red-500"
							aria-hidden="true"
						/>
						<span className="ml-2">{error}</span>
					</>
				)}
				{!error && <span className="invisible">Placeholder for height</span>}
			</p>
		</div>
	)
}
