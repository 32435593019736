import { useContext, useState } from 'react'
import moment from 'moment'
import { DateSelect, Button, ConfirmationDialog } from 'common'
import { InvoiceServices, JobsServices } from 'services'
import { revertNumberFormat } from 'utilities'
import { NotificationsContext } from 'context/notifications/toastContext'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from 'config'

interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	invoicesSelected: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setInvoicesSelected: any
	endOfMonth?: boolean
}

export const ApproveInvoices = ({
	invoicesSelected,
	setInvoicesSelected,
	endOfMonth,
}: IProps) => {
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const [completed, setCompleted] = useState(false)
	const [date, setDate] = useState(moment().format('DD/MM/YYYY'))

	const { approveInvoices } = InvoiceServices.useApproveInvoices()
	const { update } = JobsServices.useUpdateJob()
	const { showSuccess, showError } = useContext(NotificationsContext)

	const approveInvoicesHandler = async () => {
		if (invoicesSelected) {
			setIsLoading(true)
			const invoicesGroupedByJobID = invoicesSelected.reduce(
				(acc: any, invoice: any) => {
					const key = invoice.job_id
					if (!acc[key]) {
						acc[key] = []
					}
					acc[key].push(invoice)
					return acc
				},
				{}
			)

			const invoicePromises: Promise<void>[] = []
			for (const jobID in invoicesGroupedByJobID) {
				invoicePromises.push(
					new Promise(async () => {
						const invoices = invoicesGroupedByJobID[jobID]
						const formattedInvoices = invoices.map(
							(invoice: {
								id: number
								invoiceType: string
								hours?: string
								total?: string
							}) => {
								return {
									id: invoice.id,
									invoiceType: invoice.invoiceType,
									totalHours: invoice?.hours || 0,
									total: revertNumberFormat(invoice?.total!) || 0,
								}
							}
						)

						await approveInvoices(formattedInvoices, date, endOfMonth)

						if (!endOfMonth) {
							await update(Number(jobID), { job_status: 'Invoiced' })
						}
					})
				)
			}

			Promise.all(invoicePromises)
				.then(() => {
					showSuccess('All Invoices approved successfully!')
				})
				.catch((error) => {
					console.log({ error })
					showError('Something went wrong approving the Invoices')
				})

			setInvoicesSelected(null)
			setIsLoading(false)
			setCompleted(true)

			navigate(AppRoutes.privateRoutes.InvoicesJobs)
		}
	}

	return (
		<ConfirmationDialog
			isDone={completed}
			icon="info"
			title={endOfMonth ? 'End of Month' : 'Final Invoices'}
			body=" This action will approve the invoices selected. Select a Date for the process"
			triggerButton={
				<Button
					size="sm"
					variant="primary"
					onClick={() => {
						null
					}}>
					{endOfMonth ? 'End of Month' : 'Final Invoices'}
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					isLoading={isLoading}
					variant="approve"
					onClick={async () => {
						setIsLoading(true)
						await approveInvoicesHandler()
						setIsLoading(false)
						setCompleted(true)
					}}>
					Confirm
				</Button>
			}>
			<div className="flex">
				<div className="mt-4 ml-12 mb-56">
					<DateSelect
						title="Issue Date"
						id="date"
						value={date}
						onChange={(e, val) => {
							setDate(val)
						}}
					/>
				</div>
			</div>
		</ConfirmationDialog>
	)
}
