import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useCreateEvent = () => {
	const { showSuccess, showError } = useContext(NotificationsContext)
	const { putRequest, getRequest } = useApi()
	const queryClient = useQueryClient()

	const createEvent = async (event: unknown) => {
		try {
			const response = await putRequest(
				AppRoutes.serverEventsRoutes.createEvent,
				event
			)
			await getRequest(AppRoutes.serverNotesAndFilesRoutes.getAllNotesAndFiles)
			queryClient.refetchQueries(['events'])
			showSuccess('Event created successfully')
			return response
		} catch (error) {
			showError('Something went wrong creating event')
			throw new Error((error as Error).message)
		}
	}
	return { createEvent }
}
