import { useState } from 'react'
import { Button, ConfirmationDialog } from 'common'
import { JobsServices } from 'services'

interface IProps {
	job_id: number
}

export const Update = ({ job_id }: IProps) => {
	const [completed, setCompleted] = useState(false)

	const { update } = JobsServices.useProgressClaimUpdate()

	const handleNewMonth = async () => {
		setCompleted(false)
		await update(job_id, {})
		setCompleted(true)
	}

	return (
		<ConfirmationDialog
			isDone={completed}
			icon="info"
			title="Update"
			body="This action will update the completed hours and claims to date."
			triggerButton={
				<Button
					size="sm"
					variant="primary"
					onClick={() => {
						null
					}}>
					Update
				</Button>
			}
			confirmButton={
				<Button
					size="sm"
					variant="primary"
					onClick={async () => {
						handleNewMonth()
					}}>
					Confirm
				</Button>
			}>
			{/* <div className="flex">
				<div className="mt-4 ml-12 mb-40">
					<DateSelect
						title="Start Date"
						id="startDate"
						value={startDate}
						onChange={(e, val) => {
							setStartDate(val)
						}}
					/>
					<DateSelect
						title="Finish Date"
						id="finishDate"
						value={finishDate}
						onChange={(e, val) => {
							setFinishDate(val)
						}}
					/>
				</div>
			</div> */}
		</ConfirmationDialog>
	)
}
