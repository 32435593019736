import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQuery } from '@tanstack/react-query'

export const useDeleteQuoteFilesById = () => {
	const { showError } = useContext(NotificationsContext)
	const { deleteRequest } = useApi()

	const deleteQuoteFilesById = async (fileId: number) => {
		try {
			const response = await deleteRequest(
				AppRoutes.serverQuoteRoutes.deleteQuoteFileByQuoteFileId,
				fileId
			)
			return response.data
		} catch (error) {
			showError('Something went wrong deleting quote files')
			throw new Error('Something went wrong deleting quote files')
		}
	}

	return { deleteQuoteFilesById }
}
